import React from "react";
import { useForms } from "../actions/FormContext";
function FinalResultWedding() {
  const { formData } = useForms();
  return (
    <div className="resultbox">
      <div className="flex justify-between resultrow">
        <strong>Gold Purity</strong> {formData.QuotePrice.purityvalue}{" "}
      </div>
      {formData.QuotePrice.originvalue && (
        <div>
          <div className="flex justify-between resultrow">
            <strong>Diamond Origin</strong> {formData.QuotePrice.originvalue}{" "}
          </div>
          <div className="flex justify-between resultrow borderline">
            <strong>Diamond Color/Clarity</strong>{" "}
            {formData.QuotePrice.colorclarity}{" "}
          </div>
        </div>
      )}
     <div className="flex justify-between resultrow">
        <strong>Style</strong> {formData.WeddingStyle.weddingtype} 
      </div>
      
      <div className="flex justify-between resultrow">
        <strong>Type</strong> {formData.WeddingCrown.weddingtype}
      </div>
      

      {formData.MultiCenterStone1?.stone1?.stonevalue && <div className="flex justify-between resultrow">
        <strong>Stones</strong> {formData.MultiCenterStone1?.stone1?.stonevalue}
       {formData.MultiCenterStone2?.stone2?.stonevalue && `, ${formData.MultiCenterStone2.stone2.stonevalue} `} 
      </div>
        }  
       {formData.CenterStone?.stonevalue &&
      <div className="flex justify-between resultrow">
        <strong>Stone</strong>  {formData.CenterStone.stonevalue},{" "}
        {formData.CenterStone.shapevalue}, {formData.CenterStone.sizevalue}
      </div>
      }

      {/* <div className="flex justify-between resultrow">
        <strong>Ring Crown</strong> {formData.DesignCrown.crowntype}
      </div> */}
      {/* <div className="flex justify-between resultrow">
        <strong>Ring Shank</strong> {formData.DesignShank.shanktype}
        {formData.DesignShank.shankon ? ", Embedded Diamonds" : ""}
      </div> */}
      <div className="flex justify-between resultrow">
        <strong>Metal</strong> {formData.MetalType.metaltype}
      </div>
      {/* <div className="flex justify-between resultrow">
        <strong>Design Aesthetic</strong>{" "}
        {formData.DesignAesthetic.aesthetictype}
      </div> */}
      <div className="flex justify-between resultrow footertotal">
        <strong className="text-white">Estimated Price</strong>{" "}
        <b className="text-2xl">
          {" "}
          {formData.QuotePrice.estimated_minprice} -{" "}
          {formData.QuotePrice.estimated_maxprice}{" "}
        </b>
      </div>
    </div>
  );
}

export default FinalResultWedding;
