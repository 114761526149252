import React, { useState, useEffect } from "react";
import { IoIosArrowUp } from "react-icons/io";
import { useForms } from "../actions/FormContext";
import Stepper from "../shared/Stepper";
import Loading from "../shared/Loading";
import SubTitle from "./SubTitle";

const AccordionItem = ({ title, content, isActive, handleClick }) => (
  <div
    className={`accordianborder accordion-item ${
      !isActive ? "" : "open activeheader"
    }`}
  >
    <button
      className="flex accorHeader items-center justify-between w-full p-3 text-lg font-semibold  focus:outline-none"
      onClick={handleClick}
    >
      <span>{title}</span>
      <IoIosArrowUp
        className={`w-6 h-6 transition-transform duration-200 transform ${
          isActive ? "" : "rotate-180"
        }`}
      />
    </button>
    <div
      className={` bg-white contentlist  ${
        !isActive ? "slide-down " : "slide-up "
      }`}
    >
      <div>{content}</div>
    </div>
  </div>
);

const DesignYourRings = ({ sizeData, next, back,setpperback1,setpperback2,setpperback3,setpperback4 }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedItems, setSelectedItems] = useState({
    stone: null,
    shape: null,
    size: null,
  });
  const { updateFormData, formData, apiurl, bearertoken } = useForms();
  const [stonedata, setStoneData] = useState([]);
  const [shapedata, setShapeData] = useState([]);
  const [sizedata, setSizeData] = useState([]);
  const [stoneloading, setStoneLoading] = useState(true);
  const [shapeloading, setShapeLoading] = useState(true);
  const [sizeloading, setSizeLoading] = useState(true);
  const [apistoneid, setApistoneid] = useState(1);
  const [stoneid, setStoneid] = useState(1);
  const [shapeid, setShapeid] = useState(1);
  const [isDisabled, setIsDisabled] = useState(true); 
  const [error, setError] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [activestepval, setActiveSetpval] = useState(1);
  const [backval, setBackVal] = useState(1);
  const [mbDisabled, setmbDisabled] = useState(false); 
  const [currentStep, setCurrentStep] = useState(0);
  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const stoneDataclick = () => {
    setActiveIndex(1)
    setStoneid(stonedata.find(stone => stone.id === selectedItems.stone)?.id)
  }
  const shapeDataclick = () => {
    setActiveIndex(2)
  }
  const handleItemClick = (type, id) => {  
    setSelectedItems(prevSelectedItems => ({ ...prevSelectedItems, [type]: id })); 
    if(type=="size"){
     return false
    }
    if(type=="stone"){ 
      selectedItems.size=""
      setApistoneid(id);
    }
    if(type =="shape"){
      setShapeid(id);
     }

  };
  useEffect(() => {
    if (formData.MainCategories.category_name === "Engagement Ring") {
      setActiveSetpval(1);
    } else if (formData.MainCategories.category_name.trim() === "Dress Ring") {
      setActiveSetpval(2);
      setBackVal(12);
    } else if (formData.MainCategories.category_name.trim() === "Pendant") {
      setActiveSetpval(2);
      setBackVal(14);
    } else if (formData.MainCategories.category_name.trim() === "Wedding Band") {
      setActiveSetpval(3);
      setBackVal(11);
    } else if (formData.MainCategories.category_name.trim() === "Bracelet") {
      setActiveSetpval(2);
      setBackVal(16);
    } else if (formData.MainCategories.category_name.trim() === "Earrings") {
      setActiveSetpval(2);
      setBackVal(15);
    }
  }, []);
  useEffect(() => {
    setSelectedItems({      
      stone: stonedata.find((stone) => stone.id === selectedItems.stone)?.name,
      shape: stonedata.find((stone) => stone.id === selectedItems.stone)?.name,
      size: stonedata.find((stone) => stone.id === selectedItems.stone)?.name,
    });
  }, []);

  useEffect(() => {
 
    fetch(`${apiurl}/ring-builder/centerstone`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: bearertoken,
      },
    })
      .then((response) => response.json())
      .then(stonedata => 
        {
          setStoneData(stonedata.centerstone)  
          handleItemClick('stone',formData.CenterStone.stoneid) 
        })
      .then((responsedata) => {       
        setStoneLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.log(error);
        setStoneLoading(false); // Set loading to false on error as well
      });
  }, []);

  useEffect(() => {
    fetch(`${apiurl}/ring-builder/stoneshape`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: bearertoken,
      },
    })
      .then((response) => response.json()) 
    .then((shapedata) => {
      handleItemClick('shape',formData.CenterStone.shapeid) 
      setShapeData(shapedata.stoneshape)
     })
      .then((shaperesponse) => {        
        setShapeLoading(false); // Set loading to false when data is fetched
      })

      .catch((error) => {
        console.log(error);
        setShapeLoading(false); // Set loading to false on error as well
      });
  }, []);

  useEffect(() => {
 
    var apiRequestUrl =
      apiurl +
      "/ring-builder/stonesize?centerstone=" +
      apistoneid +
      "&stone_shape=" +
      shapeid;
    fetch(apiRequestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearertoken,
      },
    })
      .then((response) => response.json())
     // .then((sizedata) => setSizeData(sizedata.stoneshape))
     .then((sizedata) => {  
      setSizeData(sizedata.stoneshape)
      handleItemClick('size',formData.CenterStone.sizeid) 
     
     })
      .catch((error) => console.log(error));
    
  }, [selectedItems.stone,selectedItems.shape]);

  useEffect(() => {  
    if (selectedItems.stone && selectedItems.shape && selectedItems.size) {     
      setIsDisabled(false);
    } else {
     
      setIsDisabled(true);
    } 
   
  }, [selectedItems]);

  useEffect(() => {
    const sizeName = sizedata.find(
      (size) => size.id === selectedItems.size
    )?.name;
    setSelectedSize(sizeName);
  }, [selectedItems]);

  const handleStepChange = (step) => {
   
    setCurrentStep(step);
  }

  const handleSubmit = () => {
    document.getElementById("root").scrollIntoView();
    if (selectedItems.stone && selectedItems.shape && selectedItems.size) {
      const stoneValue = stonedata.find(
        (stone) => stone.id === selectedItems.stone
      )?.name;
      const stoneId = stonedata.find(
        (stone) => stone.id === selectedItems.stone
      )?.id;
      const shapeValue = shapedata.find(
        (shape) => shape.id === selectedItems.shape
      )?.name;
      const shapeId = shapedata.find(
        (shape) => shape.id === selectedItems.shape
      )?.id;
      const sizeValue = sizedata.find(
        (size) => size.id === selectedItems.size
      )?.name;
      const sizeId = sizedata.find(
        (size) => size.id === selectedItems.size
      )?.id;

      updateFormData("CenterStone", {
        stonevalue: stoneValue,
        stoneid: stoneId,
        shapevalue: shapeValue,
        shapeid: shapeId,
        sizevalue: sizeValue,
        sizeid:sizeId
      });
      updateFormData("MultiCenterStone1", "");
      updateFormData("MultiCenterStone2","");
      updateFormData("CenterStoneSingle","");
      
      //next();
      if (formData.MainCategories.category_name == "Engagement Ring") {
        next(3);
      } else if (
        formData.MainCategories.category_name.trim() == "Wedding Band"
      ) {
        next(5);
      } else if (formData.MainCategories.category_name.trim() == "Dress Ring") {
        next(5);
      } else if (formData.MainCategories.category_name.trim() == "Pendant") {
        next(5);
      } else if (formData.MainCategories.category_name.trim() == "Earrings") {
        next(5);
      } else if (formData.MainCategories.category_name.trim() == "Bracelet") {
        next(5);
      } else {
        next(3);
      }
    } else {
      //setError("Please select all options");
    }
  };

  return (
    <div>
      <div className="flex">
        <div>
          <h1 className="mb-textleft">
            {" "}
            {formData.MainCategories.category_name}{" "}
          </h1>
          <p className="lightfont ">
            <SubTitle></SubTitle>
          </p>
        </div>
         
        <div className="show-mobile">
        <img src={formData.MainCategories.category_img} width="120px"  alt={formData.MainCategories.category_name} />
          </div>
      </div>

      <Stepper onStepClick1={setpperback1}  onStepClick2={setpperback2}  onStepClick3={setpperback3}  onStepClick4={setpperback4} activeStep={activestepval}></Stepper>

      <div className="show-mobile">
      {
          formData.MainCategories.category_name == "Engagement Ring" ? 
          mbDisabled && <h4 className="mbselected">Selected</h4> :
           <h4 className="mbselected">Selected</h4>
        }
        <ul className="flex selectedvallist">
          {formData?.WeddingStyle?.weddingtype && (
            <li>{formData.WeddingStyle.weddingtype}</li>
          )}
          {formData?.WeddingCrown?.weddingtype && (
            <li>{formData.WeddingCrown.weddingtype}</li>
          )}

          {formData?.BraceletStyle?.bracelettype && (
            <li>{formData.BraceletStyle.bracelettype}</li>
          )}

          {formData?.EarringsCrown?.earringstype && (
            <li>{formData.EarringsCrown.earringstype}</li>
          )}

          {formData?.PendentStyle?.pendenttype && (
            <li>{formData.PendentStyle.pendenttype}</li>
          )}

          {formData?.DressStyle?.styletype && (
            <li>{formData.DressStyle.styletype}</li>
          )}
          {selectedItems.stone && (
            <li className="selected-value">
              {stonedata.find((stone) => stone.id === selectedItems.stone).name}
            </li>
          )}
          {selectedItems.shape && (
            <li className="selected-value">
              {shapedata.find((shape) => shape.id === selectedItems.shape).name}
            </li>
          )}
          {selectedItems.size && (
            <li className="selected-value">{selectedSize}</li>
          )}
        </ul>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full sm:w-2/5 md:w-40p lg:w-40p xl:w-40p  hide-mobile">
          <div className='mainImage   '>
            <div className='bg-white'>
              <img src={formData.MainCategories.category_img} alt={formData.MainCategories.category_name} />
            </div>

            <ul className="flex selectedvallist">
              {formData?.WeddingStyle?.weddingtype && (
                <li>{formData.WeddingStyle.weddingtype}</li>
              )}
              {formData?.WeddingCrown?.weddingtype && (
                <li>{formData.WeddingCrown.weddingtype}</li>
              )}

              {formData?.BraceletStyle?.bracelettype && (
                <li>{formData.BraceletStyle.bracelettype}</li>
              )}

              {formData?.EarringsCrown?.earringstype && (
                <li>{formData.EarringsCrown.earringstype}</li>
              )}

              {formData?.PendentStyle?.pendenttype && (
                <li>{formData.PendentStyle.pendenttype}</li>
              )}

              {formData?.DressStyle?.styletype && (
                <li>{formData.DressStyle.styletype}</li>
              )}
              {selectedItems.stone && (
                <li className="selected-value">
                  {
                    stonedata.find((stone) => stone.id === selectedItems.stone).name
                  }
                </li>
              )}
              {selectedItems.shape && (
                <li className="selected-value">
                  {
                    shapedata.find((shape) => shape.id === selectedItems.shape)
                      .name
                  }
                </li>
              )}
               {selectedSize && (
                <li className="selected-value">{selectedSize}</li>
              )}
            </ul>

           
          </div>
        </div>
        <div className="w-full sm:w-3/5 md:w-60p lg:w-60p xl:w-60p pl-15">
          {error && <div className="text-red text-center">{error}</div>}
          <div className="space-y-6">
            <AccordionItem
               
              title={
                <div className="text-left">
                  <span>Select center stone</span>
                  { 
                   (formData.MainCategories.category_name == "Wedding Band") &&
                  <p className="lightfont">
                  Please choose a diamond or your favorite gemstone
                  </p>
                  }
                </div>
              }
              content={
                stoneloading ? ( // Show loader if loading is true
                  <Loading />
                ) : (
                  <ul
                    onClick={() => stoneDataclick()}
                    className="grid grid-cols-2 sm:grid-cols-2 gap-4"
                  >
                    {stonedata.map((stone) => (
                      <li
                        className={`flex justify-between ${
                          selectedItems.stone === stone.id ? "active" : ""
                        }`}
                        onClick={() => handleItemClick("stone", stone.id)}
                        key={stone.id}
                      >
                        <div className="flex items-center gap-5">
                          <span style={{ fontSize: "30px" }}>
                              <img width={35} src={stone.url} />
                          </span>

                          {stone.name}
                        </div>
                        <input
                          type="radio"
                          checked={selectedItems.stone === stone.id}
                          onChange={() => {}}
                        />
                      </li>
                    ))}
                  </ul>
                )
              }
              isActive={activeIndex === 0}
              handleClick={() => handleClick(0)}
            />

                   <div className="space-y-6">
                                  <AccordionItem
                                    title="Select stone shape"
                                    content={
                                      shapeloading ? ( // Show loader if loading is true
                                        <Loading />
                                      ) : (
                                        <ul
                                          onClick={() => shapeDataclick()}
                                          className="grid grid-cols-2 md:grid-cols-2 gap-4"
                                        >
                                          {shapedata.map((shape) => (
                                            <li
                                              className={`flex justify-between ${
                                                selectedItems.shape === shape.id ? "active" : ""
                                              }`}
                                              onClick={() => handleItemClick("shape", shape.id)}
                                              key={shape.id}
                                            >
                                              <div className="flex items-center gap-5">
                                                <span style={{ fontSize: "24px" }}>
                                                  <img width={35} src={shape.url} />
                                                </span>
                                                {shape.name}
                                              </div>
                                              <input
                                                type="radio"
                                                checked={selectedItems.shape === shape.id}
                                                onChange={() => {}}
                                              />
                                            </li>
                                          ))}
                                        </ul>
                                      )
                                    }
                                    isActive={activeIndex === 1}
                                    handleClick={() => handleClick(1)}
                                  />
                                  

                                  <AccordionItem
                                    title="Select stone size"
                                    content={
                                      selectedItems.stone && selectedItems.shape ? <ul className="grid grid-cols-2 md:grid-cols-2 gap-4">
                                        {
                                          sizedata.map((size, index) => (
                                            <li
                                              className={`flex justify-between ${
                                                selectedItems.size === size.id ? "active" : ""
                                              }`}
                                              onClick={() => handleItemClick("size", size.id)}
                                              key={size.id > 0 ? size.id : index}
                                            >
                                            
                                              <span className="flex gap-2">
                                            <img width={40} src={size.url} />
                                            <div className="line-inherit flex items-center">
                                              <div > {size.name}   <p className="lightfont">{size.description}</p> </div>
                                            
                                            </div>
                                         </span>
                                              <input
                                                name="stone_size"
                                                type="radio"
                                                checked={selectedItems.size === size.id}
                                                onChange={() => {}}
                                              />
                                            </li>
                                          ))}
                                          
                                      </ul>
                                      :  <ul className='grid'>Please choose center stone and stone shape first</ul>
                                    }
                                    isActive={activeIndex === 2}
                                    handleClick={() => handleClick(2)}
                                  />

                          </div>
                
          </div>

          <div className="flex  justify-between mt-4 mb-4 btnlist">
            <button className="backbtn" onClick={() => back(backval)}>
              {" "}
              <span> {"<"} </span>Go back
            </button>
            {selectedSize ?
            <button
              disabled={isDisabled}
              className={`nextbtn ${isDisabled ? "disable" : ""}`}
              onClick={handleSubmit} >
              Next <span>{">"}</span>{" "}
            </button> :
            <button
            disabled={true}
            className={`nextbtn  disable`}
            onClick={handleSubmit} >
            Next <span>{">"}</span>{" "}
          </button>
          }
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignYourRings;
