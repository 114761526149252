import React from "react";
import { useForms } from "../actions/FormContext";

function ResultWedding() {
  const { formData } = useForms();

  return (
    <div className="resultbox">
      {/* <div className='flex justify-between resultrow'><strong>Component 1 Value</strong> {formData.MainCategories.category_name} {formData.MainCategories.category_id}</div> */}
      <div className="flex justify-between resultrow">
        <strong>Style</strong> {formData.WeddingStyle.weddingtype} 
      </div>
      <div className="flex justify-between resultrow">
        <strong>Type</strong> {formData.WeddingCrown.weddingtype}
      </div>
    
       {formData.MultiCenterStone1?.stone1?.stonevalue && <div className="flex justify-between resultrow">
        <strong>Stones</strong> {formData.MultiCenterStone1?.stone1?.stonevalue}
       {formData.MultiCenterStone2?.stone2?.stonevalue && `, ${formData.MultiCenterStone2.stone2.stonevalue} `} 
      </div>
        }  
       {formData.CenterStone?.stonevalue &&
      <div className="flex justify-between resultrow">
        <strong>Stone</strong>  {formData.CenterStone.stonevalue},{" "}
        {formData.CenterStone.shapevalue}, {formData.CenterStone.sizevalue}
      </div>
      }
       {formData.CenterStoneSingle?.stonevalue &&
      <div className="flex justify-between resultrow">
        <strong>Stone</strong>  {formData.CenterStoneSingle.stonevalue} 
      </div>
      }
      <div className="flex justify-between resultrow">
        <strong>Metal</strong> {formData.MetalType.metaltype}
      </div>
       
    </div>
  );
}

export default ResultWedding;
