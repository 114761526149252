import React, { useState, useRef, useEffect } from "react";
import { useForms } from "../actions/FormContext";
import TextSlider from "../shared/TextSlider"; 
import { AiOutlineCheck } from "react-icons/ai";
import LoadingTimer from "../shared/LoadingTimer";

const CustomRing = ({ back, next,videourl }) => {
  const [selectedImage, setSelectedImage] = useState("");
  const { updateFormData, formData, apiurl, bearertoken } = useForms();
  const [isLoading, setIsLoading] = useState(formData.ImagesData.media?false:true); 
  const [imagesdata, setImagesData] = useState([]);
  const [rowId, setRowId] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [showwhoopstext,setShowwoopstext]=useState(false)
   
  //const imagedata = formData.ImagesData.media;
 
  //   setTimeout(() => {
  //    setIsLoading(false);
  //  }, 3000);

  const handleImageSelect = (image,index) => {
    setSelectedImage(image.id);
    //const mySrc = imgRef.current[image].src;
    //console.log(image.url);
    const mySrc = image.url;
    const MyRingimg = mySrc;
    updateFormData("CustomRing", {
      myring: MyRingimg,
      selectedimage: image.id,
      imageIndex: index,
    });
    setIsDisabled(false);
    setTimeout(() => {
      // next();
     
    }, 2000);
  };
 
 
  const getImagesData = () => {
    //let multistone = [];
    var stone1val=formData.CenterStone.stonevalue;
    var stone2val;
    var multistone;
    var stone1id=formData.CenterStone.stoneid ;
    var stone2id;
    var multistoneid;
 
    if(formData.MultiCenterStone && formData.MultiCenterStone.stone1 && 'stonevalue' in formData.MultiCenterStone.stone1) {
     // multistone.push(formData.MultiCenterStone.stone1.stonevalue)
      stone1val = formData.MultiCenterStone.stone1.stonevalue
      stone1id = formData.MultiCenterStone.stone1.stoneid
      multistone = stone1val  
      multistoneid =stone1id
    }
    if(formData.MultiCenterStone && formData.MultiCenterStone.stone2 && 'stonevalue' in formData.MultiCenterStone.stone2) {
     // multistone.push(formData.MultiCenterStone.stone2.stonevalue)
     stone2val = formData.MultiCenterStone.stone2.stonevalue;
    stone2id = formData.MultiCenterStone.stone2.stoneid;
    multistone = multistone + ","  + stone2val;
    multistoneid = multistoneid + ","  + stone2id;
    }
    if(formData.MultiCenterStone){  
    } else{
      multistone = stone1val
      multistoneid = stone1id
    }

  if(formData.CenterStoneSingle){
    multistone = formData.CenterStoneSingle.stonevalue 
    multistoneid = formData.CenterStoneSingle.stoneid 
  }
   
    const newFormData = {
      stone_name: multistone,
      stone: multistone,
      center_stone_shape: formData.CenterStone.shapevalue,
      center_stone_shape_id: formData.CenterStone.shapeid,
      stone_id: multistoneid,
      stone_size: formData.CenterStone.sizevalue,
      ring_shank: formData.DesignShank.shanktype,
      is_embed_stone: formData.DesignShank.shankon,
      main_category: formData.MainCategories.category_name,
      main_category_id: formData.MainCategories.category_id,
      ring_head: formData.DesignCrown.crowntype,
      metal_colors_select: "single_color",
      shank_color: formData.MetalType.metaltype,
      one_color: formData.MetalType.metaltype,
      aesthetictype: formData.DesignAesthetic.aesthetictype,
      centerstonesingle:formData.CenterStoneSingle.stonevalue,
      centerstonesingleid:formData.CenterStoneSingle.stoneid,
      motif:formData.DesignMotif.motiftype,
      motifid:formData.DesignMotif.motifid,
      style:formData.Style.styletype,
      styleid:formData.Style.styleid,
      multistoneval:multistone,
      //multistone:[formData.MultiCenterStone.stone1.stonevalue, formData.MultiCenterStone.stone2.stonevalue],
      design_type:formData.WeddingStyle.weddingtype, 
    };
    return fetch(`${apiurl}/ring-builder/inprompt`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearertoken,
      },
      // If you need to send body data, you can include it here
      body: JSON.stringify(newFormData),
    })
      .then((response) => response.json())
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if(formData.ImagesData.media)
    {
      const mediaData = JSON.stringify(formData.ImagesData.media);
      const mediaObj  = JSON.parse(mediaData);
      setImagesData(mediaObj.media);
      setRowId(imagesdata.row_id);
      setIsLoading(false);
      const imageIndex = formData.CustomRing.imageIndex;
      handleImageSelect(mediaObj.media[imageIndex],imageIndex);
    }
    
  }, []);

  const submitFormData = async (event) => {
    if (event) {
      event.preventDefault();
    }

    try {
      const imagesdata = await getImagesData();
      setImagesData(imagesdata.media);
      setRowId(imagesdata.row_id); 
      //next();
      updateFormData("ImagesData", {
        media: imagesdata,
        rowId: imagesdata.rowId,
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      setIsLoading(false);
    } catch (error) {
      console.log("error from async", error);
    } 
  };
  useEffect(() => {
    if (!rowId && isLoading) { 
      submitFormData();
    }
  }, []);

  const handleSubmit = () => {
    document.getElementById("root").scrollIntoView();
    if (!selectedImage) {
      return;
    }
    next();
  };
  
  const showerortext = ()=>{
    setShowwoopstext(true)
  }
  return (
    <div className="container mx-auto relative">
      <div style={{display:"flex",justifyContent:"center",columnGap:"10px"}}> 
      <div>
        <h1 style={{ fontSize:"30px"}} className="text-center">
          {isLoading
            ? "Hold tight. Your beautiful designs are being generated!"
            : "Please Choose a Design"}
            
        </h1>
        <h5 className="flex justify-center text-center lightfontbold" style={{paddingBottom:"15px"}}>Designs made with AI may not be perfect. Use the images as inspiration, we will modify the design to your satisfaction!</h5>
          
       </div>   
      {isLoading && <LoadingTimer   whoopstext={showerortext}> </LoadingTimer>  }
        
      </div>
      {isLoading ? (
        <div className="flex justify-center   w-full items-center h-full z-10 mb-16">
          <div >
            <div className="relative videoheight" style={{marginBottom:"20px"}}>
            <div style={{maxWidth:"800px"}}>

          <video autoPlay muted loop id="video">
           <source src= {videourl} type="video/mp4" />
          </video>
          </div>

            
            </div>    
            { showwhoopstext ? <div className="text-center blue-font whoopsmsg" style={{position:"relative",top:"-10px"}}>Whoops! The wait is a tad longer than we thought. Hang in there for a few seconds or start over.</div>:"" } 
            <TextSlider></TextSlider>
            
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-10 mb-10 ringbuilderimgs">
          {imagesdata.map((image,index) => (
            <div
              key={image.id}
              className={`relative cursor-pointer myring image1 ${
                selectedImage === image.id ? "activeimgborder" : ""
              }`}
            >
              <img
                onClick={() => handleImageSelect(image,index)}
                src={image.url}
                alt={image.id}
                className="w-full"
              />
             
              
            <div className="customradiobtn"><label className="radio-container"> 
            <input
                type="radio"
                className="absolute top-2 right-2"
                checked={selectedImage === image.id}
                onChange={() => handleImageSelect(image,index)}
              />
              <AiOutlineCheck></AiOutlineCheck>
              <span className="radio-checkmark"></span>
            </label></div>
            </div>
          ))}
          
        </div>
      )}
      {!isLoading && (
        <div className="text-center ">
          <h5>  We're just a step away. Continue to finalize your masterpiece!  </h5>
        </div>
      )}

      <div className="flex justify-center mt-4 mb-4 mobile-btn-full">
        {/* <button disabled={isDisabled} className={`backbtn ${isDisabled ? 'disable' : ''}`} onClick={back}>
          Make More Designs
        </button> */}
        <button
          disabled={isDisabled}
          className={`nextbtn btn-lg ${isDisabled ? "disable" : ""}`}
          onClick={handleSubmit}
        >
          Continue
        </button>
      </div>
      {/* <p className="flex justify-center lightfont">Designs made with AI may not be perfect. Use the images as inspiration, we will modify the design to your satisfaction</p> */}
      

    </div>
  );
};

export default CustomRing;
