import React, { useState,useEffect } from "react";
import { useForms } from "../actions/FormContext";
import Rating from "./Rating";
function LoginForm({ onLogin }) {
  
 
  const [error, setError] = useState(false);
  const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const {  apiurl } = useForms();
    
    useEffect(() => {
      document.getElementById("root").scrollIntoView();
  }, []);   
    

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(`${apiurl}/ring-builder/login`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ user_name:username, password })
    });
    const data = await response.json();
    if (response.ok) {
        setError(false);
        onLogin();
        window.sessionStorage.setItem('username', data.logged_in_user.user_name);
        window.sessionStorage.setItem('password', data.logged_in_user.password);
       
    } else {
        setError(true);
    }
};

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if(formData.username === "jewelcounter" && formData.password === "Password1234") { 
  //     setError(false);
  //     onLogin();
      
  //   } else {
  //     setError(true);
    
  //   }
  // };

  return (
    <div className="Loginform">

      
      <div className="w-full"> 
      <h1 className="text-center"><span>Login</span></h1>
      <form onSubmit={handleSubmit}>
      {error && <p style={{color: 'red'}}>* Invalid login credentials</p>}
       <div className="mb-5"><label>UserName</label> 
       <div>  <input className="w-full border  radius-15 border-gray-300 p-2" type="text" name="username" onChange={e => setUsername(e.target.value)}  placeholder="Username" required /> </div>
       </div>

       <div><label>Password</label>   
       <div><input className="w-full border  radius-15 border-gray-300 p-2" type="password" name="password"  onChange={e => setPassword(e.target.value)}   placeholder="Password" required /> </div>
       </div>
       <div className="text-center">   <input style={{marginTop:"20px"}} className="nextbtn cursor-pointer " type="submit" value="Login" /> </div>
       
      </form>
      </div>
    </div>
  );
}

export default LoginForm;
