import React, { useState, useEffect } from "react";
import { useForms } from "../actions/FormContext";
import Loading from "./Loading";
import { IoClose } from "react-icons/io5";
function CopyRightData({ closedata }) {
  const { apiurl, bearertoken } = useForms();
  const [htmlContent, setHtmlContent] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const closePopup = () => {
    closedata();
  };

  useEffect(() => {
    fetch(`${apiurl}/ring-builder/policy?option=copy_right`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearertoken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setHtmlContent(data.copy_right);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, [apiurl]);

  return (
    <div>
      {/* <h1 className='text-center mb-12'>Copy Right</h1> */}
      {isLoading ? (
        <div>
          {" "}
          <Loading />{" "}
        </div>
      ) : (
        <div>
          {" "}
          <div style={{ justifyContent: "flex-end", display: "flex" }}>
            {" "}
            <div className="popover-clicon" onClick={closePopup}>
              {" "}
              <IoClose></IoClose>{" "}
            </div>{" "}
          </div>
          <div
            className="privacyscroll"
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          ></div>
        </div>
      )}
      <div className="flex justify-center mt-4 mb-4 gap-4">
        <button className="nextbtn" onClick={closePopup}>
          Ok Got it
        </button>
      </div>
    </div>
  );
}

export default CopyRightData;
