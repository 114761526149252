import React from "react";
import CopyRightData from "./CopyRightData";

const copyRight = ({ isOpen }) => {
  const closePopover = () => {
    isOpen();
  };

  return (
    <div
      className="popover"
      // Apply zoom animation styles
      style={{
        animation: "zoomIn 0.5s",
        transformOrigin: "center",
      }}
    >
      <CopyRightData closedata={closePopover}></CopyRightData>

      <style>
        {`
          .popover {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 15px;
            padding: 20px;
            position: absolute; 
            animation-fill-mode: forwards;
            width:100%; 
            max-width:800px;
            min-height:500px;
          }
          
          @keyframes zoomIn {
            from { transform: scale(0) }
            to { transform: scale(1) }
          }
        `}
      </style>
    </div>
  );
};

export default copyRight;
