import React, { useState } from "react";
import ResultComp from "../components/ResultComponent";
import CopyRight from "../shared/CopyRight";
import { IoClose } from "react-icons/io5";
import ResultDress from "../components/ResultDress";
import { useForms } from "../actions/FormContext";
import ResultPendent from "../components/ResultPendent";
import ResultEarrings from "../components/ResultEarrings";
import ResultWedding from "../components/ResultWedding";
import ResultBracelet from "../components/ResultBracelet";

const Popover = ({ isOpen, next }) => {
  const [PopoverOpen, setPopoverOpen] = useState(false);
  const { formData } = useForms();

  const closePopover = () => {
    isOpen();
  };
  const gotoCopyRight = () => {
    setPopoverOpen(!PopoverOpen);
  };
  const gotoNext = () => {
    //submitFormData();
    isOpen();
    next(7);
  };

  return (
    <div
      className="popover"
      // Apply zoom animation styles
      style={{
        animation: "zoomIn 0.5s",
        transformOrigin: "center",
      }}
    >
      <div style={{ justifyContent: "flex-end", display: "flex" }}>
        {" "}
        <div className="popover-clicon" onClick={closePopover}>
          {" "}
          <IoClose></IoClose>{" "}
        </div>{" "}
      </div>
      <h1 className="text-center mb-fontsize21">
        Are you happy with your choices?
      </h1>
      <p className="lightfont text-center mb-4">
        We value every detail you choose and once you submit, we will show{" "}
        <br /> your design in 100 seconds.
      </p>
        {  formData.MainCategories.category_name.trim() === "Engagement Ring" &&  <ResultComp></ResultComp>}
        {  formData.MainCategories.category_name.trim() === "Wedding Band" &&  <ResultWedding></ResultWedding> }
        {  formData.MainCategories.category_name.trim() === "Dress Ring" && <ResultDress></ResultDress> }
        {  formData.MainCategories.category_name.trim() === "Pendant" &&  <ResultPendent></ResultPendent> }
        {  formData.MainCategories.category_name.trim() === "Earrings" &&   <ResultEarrings></ResultEarrings> }
        {  formData.MainCategories.category_name.trim() === "Bracelet" &&    <ResultBracelet></ResultBracelet> }

      <div className="mt-4">
        <p className="lightfont">
          The designs shown above are in line with our{" "}
          <a className="linktext cursor-pointer" onClick={gotoCopyRight}>
            copyright policy
          </a>.
        </p>
        <p className="lightfont">
          By selecting a design and pressing continue, you confirm your
          acceptance.
        </p>
      </div>
      <div className="flex  justify-center mt-4 mb-4 gap-4">
        <button className="backbtn mb-hide" onClick={closePopover}>
          {" "}
          Go back
        </button>
        <button className="nextbtn" onClick={gotoNext}>
          Submit
        </button>
      </div>
      {PopoverOpen && (
        <div>
          <div className="backdrop">
            <CopyRight isOpen={gotoCopyRight} />
          </div>
        </div>
      )}

      <style>
        {`
          .popover {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 15px;
            padding: 20px;
            position: absolute; 
            animation-fill-mode: forwards;
            width:100%; 
            max-width:800px;
            min-height:500px;
          }
          
          @keyframes zoomIn {
            from { transform: scale(0) }
            to { transform: scale(1) }
          }
        `}
      </style>
    </div>
  );
};

export default Popover;
