import React from "react";
import { useForms } from "../actions/FormContext";
function FinalResultPendant() {
  const { formData } = useForms();
  return (
    <div className="resultbox">
      <div className="flex justify-between resultrow">
        <strong>Gold Purity</strong> {formData.QuotePrice.purityvalue}{" "}
      </div>
      {formData.QuotePrice.originvalue && (
        <div>
          <div className="flex justify-between resultrow">
            <strong>Diamond Origin</strong> {formData.QuotePrice.originvalue}{" "}
          </div>
          <div className="flex justify-between resultrow borderline">
            <strong>Diamond Color/Clarity</strong>{" "}
            {formData.QuotePrice.colorclarity}{" "}
          </div>
        </div>
      )}
      <div className="flex justify-between resultrow">
        <strong>Style</strong> {formData.PendentStyle.pendenttype} 
         
      </div>
  {formData.CenterStoneSingle?.stonevalue &&   <div className="flex justify-between resultrow">
        <strong>Stone</strong> {formData.CenterStoneSingle.stonevalue} 
      </div>
       }
      {formData.CenterStone?.stonevalue &&   <div className="flex justify-between resultrow">
        <strong>Stone</strong> {formData.CenterStone.stonevalue},{" "}
        {formData.CenterStone.shapevalue}, {formData.CenterStone.sizevalue}
      </div>
       }
      
      <div className="flex justify-between resultrow">
        <strong>Metal Type</strong> {formData.MetalType.metaltype}
      </div>
      <div className="flex justify-between resultrow">
        <strong>Aesthetic</strong>{" "}
        {formData.DesignAesthetic.aesthetictype}
      </div>
      <div className="flex justify-between resultrow footertotal">
        <strong className="text-white">Estimated Price</strong>{" "}
        <b className="text-2xl">
          {" "}
          {formData.QuotePrice.estimated_minprice} -{" "}
          {formData.QuotePrice.estimated_maxprice}{" "}
        </b>
      </div>
    </div>
  );
}

export default FinalResultPendant;
