import React from 'react'

function Loading({loadingsize}) {
    
  return (
   
    <div className= {`h-full flex  items-center justify-center  ${loadingsize && "lodingspace"} `}> 
    <div className= {`loader ${loadingsize && "LgIcon"}` }> 
    {
        loadingsize ?
        <style>
        {`        
       .loader {
         width: 150px;
         height: 150px;
         border-radius: 50%;
         position: relative;
         animation: rotate 1s linear infinite          
       }
       .lodingspace{
        margin:75px 0px;
       }
       .loader::before , .loader::after {
         content: "";
         box-sizing: border-box;
         position: absolute;
         inset: 0px;
         border-radius: 50%;
         border: 15px solid #cfcfcf;
         animation: prixClipFix 2s linear infinite ;
       }
       .loader::after{
         transform: rotate3d(90, 90, 0, 180deg );
         border-color: var(--icon-color);
       }
       @keyframes rotate {
         0%   {transform: rotate(0deg)}
         100%   {transform: rotate(360deg)}
       }
       @keyframes prixClipFix {
           0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
           50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
           75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
       }
     `}
   </style>
   :
   <style>
   {`        
  .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite
  }
  .loader::before , .loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #cfcfcf;
    animation: prixClipFix 2s linear infinite ;
  }
  .loader::after{
    transform: rotate3d(90, 90, 0, 180deg );
    border-color: var(--icon-color);
  }
  @keyframes rotate {
    0%   {transform: rotate(0deg)}
    100%   {transform: rotate(360deg)}
  }
  @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  }
`}
</style>

    }
        
    </div>
    </div>
    
  )
}

export default Loading