import React, { useState,useEffect } from "react";
import MainCategories from "./MainCategories"; 
import CenterStone from "./CenterStone";
import DesignCrown from "./DesignCrown";
import DesignShank from "./DesignShank";
import MetalType from "./MetalType";
import DesignAesthetic from "./DesignAesthetic";
import CustomRing from "./CustomRing";
import QuotePrice from "./QuotePrice";
import QuickInfo from "./QuickInfo";
import WeddingStyle from "./WeddingStyle";
import WeddingType from "./WeddingType";
import DressStyle from "./DressStyle";
import DressMotif from "./DressMotif";
import PendantStyle from "./PendantStyle";
import EarRingStyle from "./EarRingStyle";
import BraceletStyle from "./BraceletStyle";
import MulitiDesignYourRings from "./MultiCenterStone";
import CenterStoneSingle from "./CenterStoneSingle";
import Login from "./Login";
import { useForms } from "../actions/FormContext";
import Loading from "../shared/Loading";
function Home() {
  
  const [pageLoading, setPageLoading] = useState(true);
  const [pageerror, setPageerror] = useState(true); 
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Initialize state variable for login status
    const [currentStep, setCurrentStep] = useState(1);
    const [stepper1, setStepper1] = useState(1);
    const [stepper2, setStepper2] = useState(1);
    const [stepper3, setStepper3] = useState(1);
    const [stepper4, setStepper4] = useState(1);
    const [pricepop, setPricepop] = useState([]);
    const [currency, setCurrency] = useState("");
    const [traderName, setTraderName] = useState("");
    const [diamondchartimg, setdiamondchartimg] = useState("");
    const { formData,apiurl,bearertoken,updateFormData } = useForms();
    const [videourl, setVideourl] = useState("");
    const nextStep = (step) => {
      setCurrentStep(step);
    };
    const backStep = (step) => {    
      setCurrentStep(step); 
    };
    const handleLogin = () => { 
      setIsLoggedIn(true);
    } 
    
    useEffect(() => { 
      if(bearertoken) {
    //    setIsLoggedIn(true); // If token exists, set isLoggedIn to true
    }
    const username = window.sessionStorage.getItem('username');
    const password = window.sessionStorage.getItem('password'); 
   
      fetch(`${apiurl}/ring-builder/traderSettings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearertoken,
        },
        // If you need to send body data, you can include it here
        body: JSON.stringify({
          //color: firstWord,
         // metal_id: formData.MetalType.metalid,
        }),
      })
        .then((response) => response.json())
        .then((appsettings) => 
        {
          //alert("get")
        //  console.log(appsettings);
       
        if (appsettings.traderSettings.ringbuilderaccess === 1) {
          setPageerror(false)
          } else {
            setPageerror(true)
          }
          setPageLoading(false)
          setPricepop(appsettings.traderSettings.price_estimate)
          //console.log(appsettings.traderSettings.price_estimate)
             setCurrency(appsettings.traderSettings.currency_symbol)
             setdiamondchartimg(appsettings.traderSettings.diamond_color_chart)
             setVideourl(appsettings.traderSettings.manufacturingvideo)
             setTraderName(appsettings.traderSettings.cname);
            
            const fontFamily = appsettings.traderSettings.fontFamily;
            const color = appsettings.traderSettings.fontcolor;
            
            if (username && password) { 
              setIsLoggedIn(true);
            }
       
        }
        
        )
        .catch((error) => console.log(error));
    }, []);

    const setFormCategory = () => {  
    
        if (formData?.MainCategories?.category_name === "Engagement Ring") { 
         // alert("Engagement Ring")  
            setStepper1(2);   
            setStepper2(3);   
            setStepper3(4);   
            setStepper4(5);    
        }  else if (formData?.MainCategories?.category_name === "Wedding Band") {    
         // alert("Wedding Band")  
            setStepper1(10);
            setStepper2(11);      
            if(formData?.WeddingCrown?.weddingtype == "Single stone"){     
              setStepper3(18);
            }else if(formData?.WeddingCrown?.weddingtype == "Plain") {  
              setStepper3(5);
            }else{ 
              setStepper3(17);
            }       
            setStepper4(5);
            //setBackVal5(6)
          } else if (formData?.MainCategories?.category_name === "Pendant") {       
            setStepper1(14); 
            if(formData?.PendentStyle?.pendenttype == "Solitaire" || formData?.PendentStyle?.pendenttype == "Solitaire + halo" || formData?.PendentStyle?.pendenttype == "3 stone"){
              setStepper2(2); 
               }else{  
                setStepper2(18); 
               }     
            setStepper3(5);
            setStepper4(6);
            //setBackVal5(6)
          } else if (formData?.MainCategories?.category_name === "Dress Ring") {
           // alert("Dress Ring")        
            setStepper1(12);
            if(formData?.DressStyle?.styletype== "Solitaire" || formData?.DressStyle?.styletype =="Solitaire + halo" || formData?.DressStyle?.styletype== "3 stone"){ 
            setStepper2(2);
            }else{ 
            setStepper2(17);
            }     
            setStepper3(5); 
            setStepper4(6);
            
          } else if (formData.MainCategories?.category_name === "Bracelet") {      
            setStepper1(16);
            if(formData.BraceletStyle.bracelettype == "Tennis" || formData.BraceletStyle.bracelettype == "3 stone" ){
              setStepper2(17);
             }else{       
              setStepper2(currentStep);
             } 
            setStepper3(5);
            setStepper4(5);     
          } else if (formData.MainCategories?.category_name === "Earrings") {      
            setStepper1(15); 
            if(formData.EarringsCrown.earringstype == "Ear studs" || formData.EarringsCrown.earringstype =="Ear studs + halo" || formData.EarringsCrown.earringstype== "3 stone"){ 
              setStepper2(2);
             }else{ 
              setStepper2(18);
             } 
            setStepper3(5);
            setStepper4(5); 
          }
          
        
      };
    
      useEffect(() => {  
       
        updateFormData("showPricepover", {
          pricepop: pricepop,
        });
       // alert(formData.showPricepover?.pricepop)
      
      setFormCategory();  
       
 
      }, [currentStep]); 
  
      
  return (
    < >

         {pageLoading ? 
          <Loading></Loading>
          :  
          (
< >   

              {!pageerror ? ( 

              <> 
 
              {!isLoggedIn ? (
              <Login onLogin={handleLogin} /> // Pass handleLogin function as prop to Login component
              ) : (
              <>
                {currentStep === 1 && (
                  <MainCategories next={nextStep} next2={() => setCurrentStep(9)} />
                )}

                {/* Other components... */}
              </>
              )}

              {/* {currentStep === 2 && <DesignRing next={() => setCurrentStep(3)} back={() => setCurrentStep(1)} />} */}
              {currentStep === 2 && <CenterStone next={nextStep} back={backStep}  
              setpperback1={() => setCurrentStep(stepper1)} setpperback2={() => setCurrentStep(stepper2)} setpperback3={() => setCurrentStep(stepper3)} setpperback4={() => setCurrentStep(stepper4)} />}
              {currentStep === 3 && (
              <DesignCrown
                next={() => setCurrentStep(4)}
                back={() => setCurrentStep(2)}
                setpperback1={() => setCurrentStep(stepper1)} setpperback2={() => setCurrentStep(stepper2)} setpperback3={() => setCurrentStep(stepper3)} setpperback4={() => setCurrentStep(stepper4)} 
              />
              )}
              {currentStep === 4 && (
              <DesignShank
                next={() => setCurrentStep(5)}
                back={() => setCurrentStep(3)}
                setpperback1={() => setCurrentStep(stepper1)} setpperback2={() => setCurrentStep(stepper2)} setpperback3={() => setCurrentStep(stepper3)} setpperback4={() => setCurrentStep(stepper4)}
              />
              )}
              {currentStep === 5 && <MetalType next={nextStep} back={backStep} 
              setpperback1={() => setCurrentStep(stepper1)} setpperback2={() => setCurrentStep(stepper2)} setpperback3={() => setCurrentStep(stepper3)} setpperback4={() => setCurrentStep(stepper4)}
              />}
              {currentStep === 6 && (
              <DesignAesthetic next={nextStep} back={() => setCurrentStep(5)}
              setpperback1={() => setCurrentStep(stepper1)} setpperback2={() => setCurrentStep(stepper2)} setpperback3={() => setCurrentStep(stepper3)} setpperback4={() => setCurrentStep(stepper4)}
              />
              )}
              {currentStep === 7 && (
              <CustomRing
                 videourl={videourl} 
                next={() => setCurrentStep(8)}
                back={() => setCurrentStep(6)}
                setpperback1={() => setCurrentStep(stepper1)} setpperback2={() => setCurrentStep(stepper2)} setpperback3={() => setCurrentStep(stepper3)} setpperback4={() => setCurrentStep(stepper4)}
              />
              )}
              {currentStep === 8 && (
              <QuotePrice
                next={() => setCurrentStep(9)}
                back={() => setCurrentStep(7)}
                diamondimg={diamondchartimg}
                setpperback1={() => setCurrentStep(stepper1)} setpperback2={() => setCurrentStep(stepper2)} setpperback3={() => setCurrentStep(stepper3)} setpperback4={() => setCurrentStep(stepper4)}
                tradername={traderName}
              />
              )}
              {currentStep === 9 && (
              <QuickInfo
                back={() => setCurrentStep(8)}
                backtohome={() => setCurrentStep(1)}
                mycurency={currency}
                tradername={traderName}
              />
              )}

              {currentStep === 10 && (
              <WeddingStyle
              next={nextStep}
                back={() => setCurrentStep(1)}
                setpperback1={() => setCurrentStep(stepper1)} setpperback2={() => setCurrentStep(stepper2)} setpperback3={() => setCurrentStep(stepper3)} setpperback4={() => setCurrentStep(stepper4)}
              />
              )}
              {currentStep === 11 && (
              <WeddingType
              next={nextStep}
                back={() => setCurrentStep(10)}
                setpperback1={() => setCurrentStep(stepper1)} setpperback2={() => setCurrentStep(stepper2)} setpperback3={() => setCurrentStep(stepper3)} setpperback4={() => setCurrentStep(stepper4)}
              />
              )}

              {currentStep === 12 && (
              <DressStyle
              next={nextStep}
                back={() => setCurrentStep(1)}
                setpperback1={() => setCurrentStep(stepper1)} setpperback2={() => setCurrentStep(stepper2)} setpperback3={() => setCurrentStep(stepper3)} setpperback4={() => setCurrentStep(stepper4)}
              />
              )}
              {currentStep === 13 && (
              <DressMotif
                next={() => setCurrentStep(7)}
                back={() => setCurrentStep(6)}
                setpperback1={() => setCurrentStep(stepper1)} setpperback2={() => setCurrentStep(stepper2)} setpperback3={() => setCurrentStep(stepper3)} setpperback4={() => setCurrentStep(stepper4)}

              />
              )}

              {currentStep === 14 && (
              <PendantStyle
              next={nextStep}
                back={() => setCurrentStep(1)}
                setpperback1={() => setCurrentStep(stepper1)} setpperback2={() => setCurrentStep(stepper2)} setpperback3={() => setCurrentStep(stepper3)} setpperback4={() => setCurrentStep(stepper4)}

              />
              )}

              {currentStep === 15 && (
              <EarRingStyle
              next={nextStep}
                back={() => setCurrentStep(1)}
                setpperback1={() => setCurrentStep(stepper1)} setpperback2={() => setCurrentStep(stepper2)} setpperback3={() => setCurrentStep(stepper3)} setpperback4={() => setCurrentStep(stepper4)}

              />
              )}

              {currentStep === 16 && (
              <BraceletStyle
                next={nextStep}         
                back={() => setCurrentStep(1)}
                setpperback1={() => setCurrentStep(stepper1)} setpperback2={() => setCurrentStep(stepper2)} setpperback3={() => setCurrentStep(stepper3)} setpperback4={() => setCurrentStep(stepper4)}

              />
              )}

              {currentStep === 17 && (
              <MulitiDesignYourRings          
              next={nextStep}
              back={backStep}
              setpperback1={() => setCurrentStep(stepper1)} setpperback2={() => setCurrentStep(stepper2)} setpperback3={() => setCurrentStep(stepper3)} setpperback4={() => setCurrentStep(stepper4)}

              />
              )}
              {currentStep === 18 && (
              <CenterStoneSingle           
              next={nextStep}
              back={backStep}
              setpperback1={() => setCurrentStep(stepper1)} setpperback2={() => setCurrentStep(stepper2)} setpperback3={() => setCurrentStep(stepper3)} setpperback4={() => setCurrentStep(stepper4)}

              />
              )}
              </ >
              )
              :
              ( 
                <div style={{ color:"red",fontSize:"25px", display:"flex", height:"100vh",justifyContent:"center"}}><h1> You do not have access to this page.</h1></div>  
              )
              }



</ >
          )
         
      
          }
    </>
    
  )
}

export default Home