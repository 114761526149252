import React, { useState } from "react"; 
import { IoClose } from "react-icons/io5";
import { useForms } from "../actions/FormContext";
import FinalResultEngagement from "../components/FinalResultEngagement";
import FinalResultWedding from "../components/FinalResultWedding";
import FinalResultDress from "../components/FinalResultDress";
import FinalResultPendant from "../components/FinalResultPendant";
import FinalResultEarrings from "../components/FinalResultEarrings";
import FinalResultBracelet from "../components/FinalResultBracelet";
 
const PopOverFinal = ({ isOpen, next }) => {
  const [sorrymsg, setSorrymsg] = useState(false);
  const [sorrymsg2, setSorrymsg2] = useState(false);
  const { formData } = useForms();
  const opensorrymsg = () => {
    setSorrymsg(true);
    setSorrymsg2(true);
  };
  const closeWindow = () => {
    setSorrymsg(false);
    setSorrymsg2(false);
  };

  const closepricerane = () => {
    setSorrymsg(false);
    isOpen();
  };

  const gotoNext = () => {
    isOpen();
    next();
  };
  return (
    <div
      className={!sorrymsg ? "popover" : "popover smalpopover"}
      // Apply zoom animation styles
      style={{
        animation: "zoomIn 1.5s",
        transformOrigin: "center",
      }}
    >
      {!sorrymsg && (
        <div>
          <div className="finalpopoverdata">
            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              {" "}
              <div className="popover-clicon" onClick={closepricerane}>
                {" "}
                <IoClose></IoClose>{" "}
              </div>{" "}
            </div>
            <h1 className="text-center">Here is the estimated price range</h1>
            <p className="lightfont text-center mb-4">
              Taxes, shipping, and design modification may be additional{" "}
            </p>

           
            {  formData.MainCategories.category_name.trim() === "Engagement Ring" &&  <FinalResultEngagement></FinalResultEngagement>  }
        {  formData.MainCategories.category_name.trim() === "Wedding Band" &&  <FinalResultWedding></FinalResultWedding>    }
        {  formData.MainCategories.category_name.trim() === "Dress Ring" &&  <FinalResultDress></FinalResultDress> }
        {  formData.MainCategories.category_name.trim() === "Pendant" &&   <FinalResultPendant></FinalResultPendant> }
        {  formData.MainCategories.category_name.trim() === "Earrings" &&    <FinalResultEarrings></FinalResultEarrings> }
        {  formData.MainCategories.category_name.trim() === "Bracelet" &&  <FinalResultBracelet></FinalResultBracelet>     }

            <div className="flex  justify-center mt-4 mb-4 gap-4 mobile-btn-full mbreverse" style={{marginTop:"30px"}}>
              <button className="backbtn mb-fullwidth" onClick={opensorrymsg}>
                {" "}
                I don't like this price
              </button>
              <button className="nextbtn mb-fullwidth" onClick={gotoNext}>
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
      {sorrymsg2 && (
        <div>
          <div style={{ justifyContent: "flex-end", display: "flex" }}>
            {" "}
            <div className="popover-clicon" onClick={closeWindow}>
              {" "}
              <IoClose></IoClose>{" "}
            </div>{" "}
          </div>
          <h1 className="text-center">
            We are sorry you did not like the price{" "}
          </h1>
          <p className="lightfont text-center mb-4 mt-5">
            Let us do our best to make it work for you. Please click continue and describe your preference
          
          </p>

          <div className="flex  justify-center mt-4 mb-4 gap-4">
            <button className="nextbtn" onClick={gotoNext}>
              Continue
            </button>
          </div>
        </div>
      )}

      <style>
        {`
          .popover {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 15px;
            padding: 20px;
            position: absolute; 
            animation-fill-mode: forwards;
            width:100%; 
            max-width:800px;
            min-height:500px;
          }
          .popover.smalpopover{
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 15px;
            padding: 20px;
            position: absolute; 
            animation-fill-mode: forwards;
            width:100%; 
            max-width:600px;
            min-height:300px;
          }
          
          @keyframes zoomIn {
            from { transform: scale(0) }
            to { transform: scale(1) }
          }
        `}
      </style>
    </div>
  );
};

export default PopOverFinal;
