import React from "react";
import { useForms } from "../actions/FormContext";

function ResultDress() {
  const { formData } = useForms();

  return (
    <div className="resultbox">
      {/* <div className='flex justify-between resultrow'><strong>Component 1 Value</strong> {formData.MainCategories.category_name} {formData.MainCategories.category_id}</div> */}
      <div className="flex justify-between resultrow">
        <strong>Style</strong> {formData.DressStyle.styletype}          
      </div>
     {formData.CenterStone?.stonevalue &&  <div className="flex justify-between resultrow">
        <strong>Stone</strong> {formData.CenterStone.stonevalue},{" "}
        {formData.CenterStone.shapevalue}, {formData.CenterStone.sizevalue}
      </div>
     }
       {formData.MultiCenterStone1?.stone1?.stonevalue && <div className="flex justify-between resultrow">
        <strong>Stones</strong> {formData.MultiCenterStone1?.stone1?.stonevalue}
       {formData.MultiCenterStone2?.stone2?.stonevalue && `, ${formData.MultiCenterStone2.stone2.stonevalue} `} 
      </div>
        }   
      <div className="flex justify-between resultrow">
        <strong>Metal type</strong>   {formData.MetalType.metaltype}
      </div>
      <div className="flex justify-between resultrow">
        <strong>Aesthetic</strong>  {formData.DesignAesthetic.aesthetictype}
      </div>
      <div className="flex justify-between resultrow">
        <strong>Motif</strong>{" "}
        {formData.DesignMotif.motiftype}
      </div>
    </div>
  );
}

export default ResultDress;
