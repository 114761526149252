import React, { useState, useEffect } from "react";
import { useForms } from "../actions/FormContext";
import { IoClose } from "react-icons/io5";

const PrivacyPolicy = ({ isOpen }) => {
  const { apiurl, bearertoken } = useForms();
  const [htmlContent, setHtmlContent] = useState("");
  const closePopover = () => {
    isOpen();
  };
  useEffect(() => {
    fetch(`${apiurl}/ring-builder/policy?option=privacy_policy`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearertoken,
      },
    })
      .then((response) => response.json())
      .then((data) => setHtmlContent(data.copy_right))
      .catch((error) => console.error(error));
  }, [apiurl]);

  return (
    <div
      className="popover"
      style={{
        animation: "zoomIn 0.5s",
        transformOrigin: "center",
      }}
    >
      <div style={{ justifyContent: "flex-end", display: "flex" }}>
        {" "}
        <div className="popover-clicon" onClick={closePopover}>
          {" "}
          <IoClose></IoClose>{" "}
        </div>{" "}
      </div>

      {/* <h1 className='text-center mb-12'>Privacy Policy</h1> */}
      <div
        className="privacyscroll"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      ></div>

      <div className="flex  justify-center mt-4 mb-4 gap-4">
        <button className="nextbtn" onClick={closePopover}>
          Ok Got it{" "}
        </button>
      </div>
      <style>
        {`
          .popover {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 15px;
            padding: 20px;
            position: absolute; 
            animation-fill-mode: forwards;
            width:100%; 
            max-width:800px;
            min-height:500px;
          }
          
          @keyframes zoomIn {
            from { transform: scale(0) }
            to { transform: scale(1) }
          }
        `}
      </style>
    </div>
  );
};

export default PrivacyPolicy;
