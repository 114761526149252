import React from 'react' 
import { IoClose } from "react-icons/io5";
 
function PopOverimg({isOpenimg,diamondimg}) {

    const closeimg = () => { 
        isOpenimg();
      };
  return (
    <div
    className="popover"
    // Apply zoom animation styles
    style={{
      animation: "zoomIn 0.5s",
      transformOrigin: "center",
    }}
  >
       <div style={{ justifyContent: "flex-end", display: "flex" }}> 
              <div className="popover-clicon" onClick={closeimg}> 
                <IoClose></IoClose> 
              </div> 
        </div>
   <div className='popoverbody'>  <img src={diamondimg} alt="diamondimg" />  </div>



<style>
        {`
          .popover {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 15px;
            padding: 20px;
            position: absolute; 
            animation-fill-mode: forwards;
            width:100%; 
            max-width:80%;
            min-height:500px;
            max-height:90vh;
            height: auto;
          }
          .popoverbody{
            max-height:80vh;
            overflow-y:auto;
          }
          
          @keyframes zoomIn {
            from { transform: scale(0) }
            to { transform: scale(1) }
          }
        `}
      </style>
    </div>
  )
}

export default PopOverimg