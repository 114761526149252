import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useForms } from "../actions/FormContext";
const TextSlider = () => {
  const [apiSliderText, setApiSliderText] = useState([]);
  const { apiurl, bearertoken } = useForms();
  const settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 7000,
    autoplay: true, // Enable autoplay
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    pauseOnFocus: false,
    showPrevArrow: false, // Hide the previous button
    showNextArrow: false, // Hide the next button
  };
  useEffect(() => {
    fetch(`${apiurl}/ring-builder/fun-factors`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: bearertoken,
      },
    })
      .then((response) => response.json())
      .then((textdata) => setApiSliderText(textdata.fun_factors));
  }, []);
  return (
    <Slider {...settings}>
      {apiSliderText.map((fact, index) => (
        <div key={index}>
          <h1 className="text-center">
            {fact.title} #{index + 1}{" "}
          </h1>
          <p className="text-center"> {fact.description} </p>
        </div>
      ))}
    </Slider>
  );
};

export default TextSlider;
