import React from "react";

function ThankYou({ setgotohome }) {
  const gothomepage = () => {
    setgotohome();
  };
  document.getElementById("root").scrollIntoView();
  
  return (
    <div
      className="popover "
      id="thanksPopup"
      // Apply zoom animation styles
      style={{
        animation: "zoomIn 0.5s",
        transformOrigin: "center",
      }}
    >
      <div className="flex justify-center"> 
        <img width={80} src="thankicon.svg" /> 
      </div>

      <h1 className="text-center">Thank you for your submission </h1>
      <p className="text-center  drak-gray mt-12 mb-12">
       We will get in touch with you soon!
      </p>

      <div className="flex  justify-center mt-4 mb-4 gap-4">
        {/* <button className='backbtn mb-hide' onClick={closePopover}>   Close</button> */}
        <button className="nextbtn widthfull" onClick={gothomepage}>
          Explore more prodcuts
        </button>
      </div>

      <style>
        {`
          .popover {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 15px;
            padding: 20px;
            position: absolute; 
            animation-fill-mode: forwards;
            width:100%; 
            max-width:600px;
            min-height:400px;
          }
          h1{ font-size:35px;}
          .backbtn,nextbtn{ width:150px}
          @keyframes zoomIn {
            from { transform: scale(0) }
            to { transform: scale(1) }
          }
        `}
      </style>
    </div>
  );
}

export default ThankYou;
