import React, { useState, useEffect } from "react";
import { useForms } from "../actions/FormContext";
import SubmitYourDesign from "./SubmitYourDesign";
import Loading from "../shared/Loading";

function MainCagegories({ next, next2,  }) {
  document.getElementById("root").scrollIntoView();
  const [maincategories, setData] = useState([]);
  const [value, setValue] = useState("");
  const {formData,resetFormData , updateFormData, apiurl, bearertoken } = useForms();
  const [loading, setLoading] = useState(true); // Add loading state
  useEffect(() => {
    
    // This will be executed whenever the component is mounted
    resetFormData();
  }, []); // Empty array means this effect will only be run on component mount and unmount 

  useEffect(() => {
    fetch(`${apiurl}/ring-builder/categories`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: bearertoken,
      },
    })
      .then((response) => response.json())
      .then((maincategories) => {
        setData(maincategories.categories);
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // Set loading to false on error as well
      });
      updateFormData("CustomRing", "");
      updateFormData("DesignShank", "");

      
     
  }, []);

  const getCategoriesval = (e) => {
  
    setValue(e.target.value);
    if (e.target.value) {
      const imgPath = e.target.name;
      updateFormData("MainCategories", {
        category_name: e.target.value,
        category_id: e.target.id,
        category_img: imgPath,
      });
      // setCurrentStep(2)

      if (e.target.value == "Engagement Ring") {
        next(2);
      } else if (e.target.value == "Wedding Band") {
        next(10);
      } else if (e.target.value == "Dress Ring") {
        next(12);
      } else if (e.target.value == "Pendant") {
        next(14);
      } else if (e.target.value == "Bracelet") {
        next(16);
      } else if (e.target.value == "Earrings") {
        next(15);
      }
    }
  };

  return (
    <div>
      <h1 className="text-center categoryhead">Create your unique jewelry design using AI</h1>
      <p className="text-center headingtitle">
      Customize your product using AI, choose your favourite designs, and we'll get in touch with you to provide a <br />
      FREE consulting session!{" "}
      </p>

      {loading ? ( // Show loader if loading is true
        <Loading />
      ) : (
        <ul className="categories  grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {maincategories.map((category, key) => (
            <li className="p-4 relative" key={key}>
              <input
                name={category.url}
                className="absolute cursor-pointer top-0 left-0 w-full h-full opacity-0 z-10"
                type="radio"
                id={category.id}
                value={category.name}
                checked={value === `{category.name}`}
                onChange={getCategoriesval}
              />
              <div className="catimg relative transform transition-all duration-300 hover:scale-100">
                <img src={category.url} />{" "}
              </div>
              {/* <span>{key}: </span> */}
              <h4 className="text-center relative top-3 catname">
                {category.name}
              </h4>
            </li>
          ))}
        </ul>
      )}

      {/* <button id="submitbtn" onClick={handleSubmit}>Next</button> */}
      <SubmitYourDesign next2={next2}></SubmitYourDesign>
    </div>
  );
}

export default MainCagegories;
