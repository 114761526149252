import React from "react";
import { useForms } from "../actions/FormContext";

function Result() {
  const { formData } = useForms();

  return (
    <div className="resultbox">
      {/* <div className='flex justify-between resultrow'><strong>Component 1 Value</strong> {formData.MainCategories.category_name} {formData.MainCategories.category_id}</div> */}
      <div className="flex justify-between resultrow">
        <strong>Center stone</strong> {formData.CenterStone.stonevalue},{" "}
        {formData.CenterStone.shapevalue}, {formData.CenterStone.sizevalue}
      </div>
      <div className="flex justify-between resultrow">
        <strong>Ring crown</strong> {formData.DesignCrown.crowntype}
      </div>
      <div className="flex justify-between resultrow">
        <strong>Ring shank</strong> {formData.DesignShank.shanktype}
        {formData.DesignShank.shankon ? ", Embedded diamonds" : ""}{" "}
      </div>
      <div className="flex justify-between resultrow">
        <strong>Metal type</strong> {formData.MetalType.metaltype}
      </div>
      <div className="flex justify-between resultrow">
        <strong>Design aesthetic</strong>{" "}
        {formData.DesignAesthetic.aesthetictype}
      </div>
    </div>
  );
}

export default Result;
