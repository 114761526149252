import React, { useState, useEffect } from "react";
import { useForms } from "../actions/FormContext";
import { FiDownload } from "react-icons/fi"; 
import { BsFillShareFill } from "react-icons/bs"; 
import { GoQuestion } from "react-icons/go";
import { IoIosArrowUp } from "react-icons/io";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import PopOverFinal from "../shared/PopOverFinal";
import SocialMedia from "../shared/SocialMedia";
import PopOverimg from "../shared/PopOverimg"; 

const AccordionItem = ({ title, content, isActive, handleClick, isLast }) => (
  <div
    className={`accordianborder accordion-item ${
      !isActive ? "" : "open activeheader"
    }`}
  >
    <button
      className="flex accorHeader items-center justify-between w-full p-3 text-lg font-semibold  focus:outline-none"
      onClick={handleClick}>
      <span>{title}</span>
      <IoIosArrowUp
        className={`w-6 h-6 transition-transform duration-200 transform ${
          isActive ? "" : "rotate-180"
        }`}
      />
    </button>
    <div
      className={` bg-white contentlist  ${
        !isActive ? "slide-down " : "slide-up "
      }`}
    >
      <div>{content}</div>
    </div>
  </div>
);

const marks = {
  0: "F/VVS1",
  33: "G/VS1",
  66: "H/SI1",
  100: "I/I1",
};
function QuotePrice({ next, back,diamondimg,tradername }) {
  const [activeIndex1, setActiveIndex1] = useState(true);
  const [activeIndex2, setActiveIndex2] = useState(false);
  const [activeIndex3, setActiveIndex3] = useState(false);
  const [dimondselected, setDiamondSelected] = useState(true);
  const [selectedItems, setSelectedItems] = useState({
    purity: null,
    shape: null,
    size: null,
  });
  const { updateFormData, formData, apiurl, bearertoken } = useForms();
  const [PopoverOpen, setPopoverOpen] = useState(false);
  const [popoverImg, setpopoverImg] = useState(false);
  const [puritydata, setPurityData] = useState([]);
  const [shapedata, setShapeData] = useState([]);
  const [colorclaritydata, setColorClarityData] = useState([]);
  const [slidervalue, setSliderValue] = useState([66]);
  const [colorclarity, setColorClarity] = useState("H/SI1");
  const [estimatedprice, setEstimatedPrice] = useState([]);
  const metalType = formData.MetalType.metaltype;
  const metalId = formData.MetalType.metalId;
  const firstWord = metalType.split(" ")[0];
  const [isDisabled, setIsDisabled] = useState(true);
  const [social, setSocial] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const handleSliderChange = (newValue) => {
    // Allow dragging only on fixed values
    const isFixedValue = Object.keys(marks).includes(newValue.toString());
    setSliderValue(isFixedValue ? newValue : slidervalue);
    setColorClarity(marks[newValue]);
  };

  const purityeDataclick = () => {
    setActiveIndex2(true);
  };
  const diamondDataclick = () => {
    setActiveIndex3(true);
  };
  const handleToggleAccordion1 = () => {
    setActiveIndex1(!activeIndex1);
  };
  const handleToggleAccordion2 = () => {
    setActiveIndex2(!activeIndex2);
  };
  const handleToggleAccordion3 = () => {
    setActiveIndex3(!activeIndex3);
  };
  const handleItemClick = (type, id) => {
    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [type]: id,
    }));
  };
  const handleSubmit = async (event) => {    
    document.getElementById("root").scrollIntoView();
    //console.log("submit handler",event);
    if (event) {
      event.preventDefault();
    }

    try { 

      const resp = await getEstimatedPrice();
      setEstimatedPrice(resp.estimate_price);
      updateEstimatedPrice(resp.estimate_price);

      if(formData.showPricepover?.pricepop==0){
        next(9)
      }else{ 
      if (isDisabled == false) {       
        setPopoverOpen(!PopoverOpen);       
      }
      }

    } catch (error) {
      console.log("error from async", error);
    }
  };

   const closePoup = ()=>{
    setpopoverImg(!popoverImg);  
   }
   const closePoupimg = (event)=>{
    event.stopPropagation();
    setpopoverImg(!popoverImg);  
   }
  const updateEstimatedPrice = (estimated_price_new) => {
    if (selectedItems.purity) {
      const purityValue = puritydata.find(
        (purity) => purity.id === selectedItems.purity
      )?.name;
      const diamondOriginValue = shapedata.find(
        (shape) => shape.id === selectedItems.shape
      )?.name;
      const diamondClarityValue = colorclarity;
      const estimated_min_value = estimated_price_new[0].minprice;
      const estimated_max_value = estimated_price_new[0].maxprice;
      const estimated_min_rangevalue = estimated_price_new[0].num_minprice;
      const estimated_max_rangevalue = estimated_price_new[0].num_maxprice;
      const currency =  estimated_price_new[0].currency;


      //const diamondClarityValue = sizedata.find(size => size.id === selectedItems.size)?.name;
      //if(estimatedprice.length){

      updateFormData("QuotePrice", {
        purityvalue: purityValue,
        originvalue: diamondOriginValue,
        estimated_minprice: estimated_min_value,
        estimated_maxprice: estimated_max_value,
        estimated_minrange: estimated_min_rangevalue,
        estimated_maxrange: estimated_max_rangevalue,
        currency_symbol: currency,
        colorclarity: diamondClarityValue,
      });


      //}

      // next();
    } else {
      alert("Please select all options");
    }
  };

  const getEstimatedPrice = () => {
    if (dimondselected) {
      var [color, clarity] = colorclarity.split("/");
      var diamondOriginValue = shapedata.find(
        (shape) => shape.id === selectedItems.shape
      )?.id;
    } else {
      var color = null;
      var clarity = null;
      var diamondOriginValue = null;
    }

    const is_embed_stone = formData.DesignShank.shankon ? 1 : 0;

    const metalTypeId = puritydata.find(
      (purity) => purity.id === selectedItems.purity
    )?.metal_type_id;
    
    let subcategory_id = '';
      if (formData.MainCategories.category_name === "Engagement Ring") {
        subcategory_id = formData.DesignAesthetic.sub_category_id; //DesignCrown
      } else if (formData.MainCategories.category_name === "Wedding Band") {
        subcategory_id = formData.WeddingStyle.sub_category_id;
      } else if (formData.MainCategories.category_name === "Dress Ring") {
        subcategory_id = formData.DesignAesthetic.sub_category_id; //DressStyle       
      }else if (formData.MainCategories.category_name === "Pendant") {
        subcategory_id = formData.DesignAesthetic.sub_category_id; //PendentStyle 
      }else if (formData.MainCategories.category_name === "Bracelet") {
        subcategory_id = formData.DesignAesthetic.sub_category_id; //BraceletStyle
      }else if (formData.MainCategories.category_name === "Earrings") {
        subcategory_id = formData.DesignAesthetic.sub_category_id; //EarringsCrown
      }else{
        subcategory_id = "";
      }
      
    const getPriceData = {  
      category_id: formData.MainCategories.category_id,
      centerstoneshape: formData.CenterStone.shapevalue,
      stone_size: formData.CenterStone.sizevalue,
      stone_name: formData.CenterStone.stonevalue,
      stone_id: formData.CenterStone.stoneid,
      stone_type: diamondOriginValue,
      subcategory_id: subcategory_id,
      is_embed_stone: is_embed_stone,
      metal_id: metalTypeId,
      row_id: formData.ImagesData.media.row_id,
      colour: color,
      clarity: clarity,
    };

   

    return (
      fetch(`${apiurl}/ring-builder/priceestimate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearertoken,
        },
        // If you need to send body data, you can include it here
        body: JSON.stringify(getPriceData),
      })
        .then((response) => response.json())
        //.then((estimate) => {setEstimatedPrice(estimate.estimate_price)})
        .catch((error) => console.log(error))
    );
  };

  const skipprice = () => {
    document.getElementById("root").scrollIntoView();
    next();
  };

  const handleDownload = () => {
    if (formData.CustomRing?.myring !== undefined) {
      let filename = "my_custom_"+formData.MainCategories.category_name+".jpg";
      filename = filename.toLowerCase(filename);
      filename = filename.toLowerCase(filename);
      filename = filename.replace(/ /g, '_');

      const link = document.createElement("a");
      link.href = apiurl+"/ring-builder/download/"+formData.CustomRing.selectedimage;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
        // set state to downloaded
      setDownloaded(true);

   // hide success message after 3 seconds
   setTimeout(() => setDownloaded(false), 5000);
    } else {
      
    }
  };

  useEffect(() => {   
   // console.log(formData);
   let diamondselect = 2;
   if (formData.CenterStone?.stoneid) {
    diamondselect = formData.CenterStone.stoneid;
  } else if (formData.CenterStoneSingle?.stoneid) {
    diamondselect = formData.CenterStoneSingle.stoneid;
  } else if (formData.MultiCenterStone1?.stone1?.stoneid == 1) {
    diamondselect = formData.MultiCenterStone1.stone1.stoneid;
  } else if (formData.MultiCenterStone2?.stone2?.stoneid) {
    diamondselect = formData.MultiCenterStone2.stone2.stoneid;
  }
    if (diamondselect != "1"  ) {
       
      setDiamondSelected(false);

      if (selectedItems.purity) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
       
      if (selectedItems.purity && selectedItems.shape) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [selectedItems, slidervalue]);

  const handleShare = () => {
  //  console.log(formData);
    setSocial(!social)
    // if (navigator.share) {
    //   navigator
    //     .share({
    //       title: "Image",
    //       text: "Check out this image",
    //       url: formData.CustomRing.myring,
    //     })
    //     .then(() => console.log("Image shared successfully"))
    //     .catch((error) => console.log("Error sharing image", error));
    // }
  };
  useEffect(() => {
    fetch(`${apiurl}/ring-builder/metal-purity`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearertoken,
      },
      // If you need to send body data, you can include it here
      body: JSON.stringify({
        color: firstWord,
        metal_id: formData.MetalType.metalid,
      }),
    })
      .then((response) => response.json())
      .then((puritydata) => setPurityData(puritydata.purity))
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    fetch(`${apiurl}/ring-builder/diamond-origin`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: bearertoken,
      },
    })
      .then((response) => response.json())
      .then((shapedata) => setShapeData(shapedata.diamod_origin))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    fetch(`${apiurl}/ring-builder/color-clarity`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: bearertoken,
      },
    })
      .then((response) => response.json())
      .then((colorclaritydata) =>
        setColorClarityData(colorclaritydata.color_clarity)
      )
      .catch((error) => console.log(error));
  }, []);


  // Set the first item as selected by default
  useEffect(() => {
   // if (puritydata[0]) {
    if (puritydata.length === 1) {

      setSelectedItems(prevState => ({...prevState, purity: puritydata[0].id}));
    }
  }, [puritydata]);  // Add puritydata as dependency


  const handleIconClick = (iconId) => { 
    let newSliderValue = Math.round((iconId / (colorclaritydata.length - 1)) * 100);
    setSliderValue(newSliderValue); 
    if(newSliderValue == 67){
      newSliderValue =newSliderValue-1;
    }
    setColorClarity(marks[newSliderValue]); 

  };
  return (
    <div style={{ position: "relative" }}>
      <h1 className="mb-textcenter"> Choose your materials </h1>
      {/* <p className="lightfont mb-textcenter">
        Only 3 questions. Please give us more information where applicable. It
        may be pre-selected based on your choices.
      </p> */}
  <div>  {downloaded && <span className="  text-green-500">Your image is being downloaded automatically</span>}</div>
      <div className="flex flex-wrap mt-8">
        <div className="w-full sm:w-2/5 md:w-40p lg:w-40p xl:w-40p">
          <div className="mainImage relative mb-mb-20">
          <div className='bg-white'>
            <img
              src={formData.CustomRing.myring}
              alt={`Selected image `}
              className="w-full"
              key="myring"
            />
            </div>
            
            <div className="sharedicons">
              <div className="cursor-pointer fileshare">
                <FiDownload onClick={handleDownload}></FiDownload>
              </div>
              <div className="cursor-pointer fileshare relative">
                <BsFillShareFill onClick={handleShare}></BsFillShareFill>   
               {social &&  <div className="scoialiconslist"> 
              <SocialMedia tradername={tradername}></SocialMedia>
              </div> 
               }            
              </div>
             
            </div>
           
          
          </div>
        </div>
        <div className="w-full sm:w-3/5 md:w-60p lg:w-60p xl:w-60p pl-15">
          {/* Content for the second column */}
          <div  className="alignbtns"> 
          <div className="space-y-6">
            <AccordionItem
              title={
                <div className="text-left">
                  <span>
                    Choose <span style={{textTransform:"lowercase"}}>{formData.MetalType.metal_form}</span> purity
                  </span>
                </div>
              }
              content={
                <ul
                  onClick={() => purityeDataclick()}
                  className="grid grid-cols-2 md:grid-cols-2 gap-4"
                >
                  {puritydata.map((purity) => (
                    <li
                      className={`flex justify-between ${
                        selectedItems.purity === purity.id ? "active" : ""
                      }`}
                      onClick={() => handleItemClick("purity", purity.id)}
                      key={purity.id}
                    >
                      <div className="flex items-center gap-5">
                        <span style={{ fontSize: "24px" }}>
                          <img width={30} src={purity.url} key={"img"+purity.id} />
                        </span>
                        {purity.name}
                      </div>

                      <input
                        type="radio"
                        checked={selectedItems.purity === purity.id}
                        onChange={() => {}}
                      />
                    </li>
                  ))}
                </ul>
              }
              isActive={activeIndex1}
              handleClick={handleToggleAccordion1}
            />
            {dimondselected && (
              <div className="space-y-6">
                <AccordionItem
                  title="Choose diamond origin"
                  content={
                    <ul
                      onClick={() => diamondDataclick()}
                      className="grid grid-cols-2 md:grid-cols-1 gap-4 customlist-mb"
                    >
                      {shapedata.map((shape) => (
                        <li
                          className={`flex justify-between ${
                            selectedItems.shape === shape.id ? "active" : ""
                          }`}
                          onClick={() => handleItemClick("shape", shape.id)}
                          key={shape.id}
                        >
                          <div className="flex items-center gap-5">
                            <span
                              className="flex gap-2"
                              style={{ fontSize: "16px" }}
                            >
                              <img width={30} src={shape.url} />
                              <div className="line-inherit">
                                <div>{shape.name}</div>
                                <p className="lightfont">{shape.description}</p>
                              </div>
                            </span>
                          </div>

                          <input
                            type="radio"
                            checked={selectedItems.shape === shape.id}
                            onChange={() => {}}
                          />
                        </li>
                      ))}
                    </ul>
                  }
                  isActive={activeIndex2 === true}
                  handleClick={handleToggleAccordion2}
                />
                <AccordionItem
                  title={
                    <div className=" relative flex items-center gap-1">
                       <div>Choose diamond colour and clarity</div>
                       <GoQuestion   data-tooltip-target="tooltip-default"   onClick={closePoupimg} />
                      
                                  
                       {/* <div className="group relative flex max-w-max flex-col items-center justify-center">

                       <div className="mx-auto inline-flex w-full justify-center "> 
                      <div className="absolute left-1/2 top-5 ml-auto mr-auto min-w-max -translate-x-1/2 scale-0 transform rounded-lg text-xs font-medium transition-all duration-500 group-hover:scale-100">
                        <div className="flex max-w-xs flex-col items-center shadow-lg">
                          <div className="clip-bottom h-2 w-4 bg-gray-800"></div>
                          <div style={{fontSize:"8px"}} className="rounded bg-gray-800 p-1 text-center text-xs text-white  ">
                            click on the ? icon to view the <br/> diamond colour - clarity chart
                          </div>
                        </div>
                      </div>
                    </div>
                    </div> */}
      
                    </div>
                  }
                  isLast
                  content={
                    <div className="p-4">
                      <div   className="flex justify-between   clarityicons">
                         {colorclaritydata.map((colorclaritydata,index) => {
                          return (
                            <img
                              className="colorclarityicon"
                              src={colorclaritydata.url}
                              onClick={() => handleIconClick(index)}
                              key={index}
                            />
                          );
                        })}
                      </div>

                      <div
                        className="dragingslider slider-nodots"
                        key={colorclaritydata.id}
                      >
                        
                        <Slider
                          min={0}
                          max={100}
                          value={slidervalue}
                          marks={marks}
                          onChange={handleSliderChange}
                          step={null} // Setting step as null restricts dragging between points
                          dotStyle={{ borderColor: "grey" }}
                        />
                      </div>
                    </div>
                  }
                  isActive={activeIndex3 === true}
                  handleClick={handleToggleAccordion3}
                />
              </div>
            )}
          </div>
          </div>
          <div className="hidden   mt-5">
            <button className="backbtn mb-fullwidth" onClick={skipprice}>
              Skip Price
            </button>
          </div>
          <div className="flex  justify-between mt-4 mb-4  ">
            <button className="backbtn" onClick={back}>
              <span>{"<"}</span> Go back
            </button>
            <div className="flex gap-4">
              {/* <button className="backbtn hide-mobile" onClick={skipprice}>
                {" "}
                Skip Price
              </button> */}
              <button
                disabled={isDisabled}
                className={`nextbtn ${isDisabled ? "disable" : ""}`}
                onClick={handleSubmit}
              >
                Next <span>{">"}</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {PopoverOpen && (
        <div>
          <div className="backdrop">
            <PopOverFinal isOpen={handleSubmit} next={next} />
          </div>
        </div>
      )}
       {popoverImg && (
        <div>
          <div className="backdrop">
            <PopOverimg diamondimg={diamondimg} isOpenimg={closePoup } />  
          </div>
        </div>
      )}
    </div>
  );
}

export default QuotePrice;
