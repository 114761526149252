import React from "react";
import { useForms } from "../actions/FormContext";

function ResultEarrings() {
  const { formData } = useForms();

  return (
    <div className="resultbox">
      {/* <div className='flex justify-between resultrow'><strong>Component 1 Value</strong> {formData.MainCategories.category_name} {formData.MainCategories.category_id}</div> */}
      <div className="flex justify-between resultrow">
        <strong>Style</strong> {formData.EarringsCrown.earringstype} 
         
      </div>
      {formData.CenterStoneSingle?.stonevalue &&  <div className="flex justify-between resultrow">
        <strong>Stone</strong> {formData.CenterStoneSingle.stonevalue} 
      </div>
       }

        {formData.CenterStone?.stonevalue && <div className="flex justify-between resultrow">
        <strong>Stone</strong> {formData.CenterStone.stonevalue},{" "}
        {formData.CenterStone.shapevalue}, {formData.CenterStone.sizevalue}
      </div>
         }
      <div className="flex justify-between resultrow">
        <strong>Metal type</strong>   {formData.MetalType.metaltype}
      </div>
      <div className="flex justify-between resultrow">
        <strong>Aesthetic</strong>  {formData.DesignAesthetic.aesthetictype}
      </div>
       
    </div>
  );
}

export default ResultEarrings;
