import React, { useState } from 'react';
import StarRatings from 'react-star-ratings';
import { useForms } from "../actions/FormContext";
import { IoClose } from "react-icons/io5";
const RatingForm = ({ isOpen,closepoup2,submitFormData }) => {
  const maxCount = 150; 
  const [inputLength, setInputLength] = useState(0); // Initial character count is 0
  const {  formData, apiurl, bearertoken } = useForms();
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [error, setError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const closePopover = () => {
    isOpen();
  };
  const changeRating = (newRating) => {
    setRating(newRating);
    setError(false);
  }

  const handleCommentChange = (event) => {
    setComment(event.target.value);
    setInputLength(event.target.value.length);
    
  }

  const handleSubmit = (event) => {
    const row_id = formData.CustomRing?.myring
      ? formData.ImagesData.media.row_id
      : null;
    event.preventDefault();
    if (rating === 0) {
      setError(true);
      setIsDisabled(false);
      return;
    }
  
    // Replace this URL with your API endpoint
    const api_url = apiurl+"/ring-builder/feedback" 
    
    fetch(api_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearertoken,
      },
      body: JSON.stringify({ rating, comment,row_id }),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => {
        console.error('Error:', error);
      });
    
    setRating(0);
    setComment('');
    setError(false);
    setIsDisabled(false);
    closepoup2();
    isOpen();
    submitFormData();
  }
  
  return (
    <div
    className="popover rating"
    // Apply zoom animation styles
    style={{
      maxWidth:"700px",padding: "30px 70px",animation: "zoomIn 0.5s",
      transformOrigin: "center",
    }}
  >
   <div className='popover-cliconalign'> 
      <div className="popover-clicon" onClick={closePopover}>
          
          <IoClose></IoClose> 
        </div> 
        </div>
       
        <h1 className="text-center">Your feedback is valuable to us! </h1>
        <p className='text-center font-bold' style={{marginTop:"20px"}}>How did you like the designs?</p>
    <form onSubmit={handleSubmit}>
   
        <div className='text-center'  style={{margin:"40px 0px "}}> 
      <StarRatings 
        rating={rating}
        starEmptyColor="#D3D3D3"
        starRatedColor="#003459"
        starHoverColor="#003459"
        changeRating={changeRating}
        numberOfStars={5}
        name="rating"
       
      />
       {error && <div className='text-center' style={{ color: 'red' }}>Please select a rating.</div>}
      </div>
      
      <label>
      <div> 
        

        <textarea   
                    className="w-full border no-resize radius-15 border-gray-300 p-2"
                    id="message"
                    maxLength={maxCount}
                    name="comments"
                    value={comment}
                    onChange={handleCommentChange}
                    rows="3"
                    placeholder="How was your design experience? How can we improve this AI offering? We'd love to hear from you!"
                  ></textarea>
                  <div style={{position:"relative", fontSize:"12px", color:"#969696", top:"0px",right:"0px"}} className="text-right ">{maxCount - inputLength} characters left</div>
        </div>
      </label>
   
      {/* <input type="submit" value="Submit" /> */}
     <div className='text-center'> <button
                disabled={isDisabled}
                className={`nextbtn ${isDisabled ? "disable" : ""}`}
                type="submit"
              >
                Submit
              </button>
              </div> 

 
  




      <style>
        {`
          .popover {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 15px;
            padding:40px 20px;
            position: absolute; 
            animation-fill-mode: forwards;
            width:100%;  
            max-width:900px;
            min-height:400px;
          }
          .popover-clicon{ right:-50px}
          .popover-cliconalign {
            justify-content: flex-end; display: flex;
          }
          .imgcloseicon {
            border: 1px solid #d8d8d8;
            border-radius: 50%;
            width: 30px;top:10px;
            height: 30px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          h1{ font-size:35px;}
          .backbtn,nextbtn{ width:150px}
          @keyframes zoomIn {
            from { transform: scale(0) }
            to { transform: scale(1) }
          }
        `}
      </style>
    </form>
    </div>
  );
}

export default RatingForm;
