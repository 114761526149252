import React, { useState, useEffect } from "react";

import { IoIosArrowUp } from "react-icons/io";
import { IoDiamondSharp } from "react-icons/io5";
import { useForms } from "../actions/FormContext";
import Stepper from "../shared/Stepper";
import Loading from "../shared/Loading";
import SubTitle from "./SubTitle";

const AccordionItem = ({ title, content, isActive, handleClick }) => (
  <div
    className={`accordianborder accordion-item ${
      !isActive ? "" : "open activeheader"
    }`}
  >
    <button
      className="flex accorHeader items-center justify-between w-full p-3 text-lg font-semibold  focus:outline-none"
      onClick={handleClick}
    >
      <span>{title}</span>
      <IoIosArrowUp
        className={`w-6 h-6 transition-transform duration-200 transform ${
          isActive ? "" : "rotate-180"
        }`}
      />
    </button>
    <div
      className={` bg-white contentlist  ${
        !isActive ? "slide-down " : "slide-up "
      }`}
    >
      <div>{content}</div>
    </div>
  </div>
);

const CenterStoneSingle = ({ next, back,setpperback1,setpperback2,setpperback3,setpperback4 }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedItems, setSelectedItems] = useState({ stone: null});
  const { updateFormData, formData, apiurl, bearertoken } = useForms();
  const [stonedata, setStoneData] = useState([]);
  const [stoneloading, setStoneLoading] = useState(true);
  const [apistoneid, setApistoneid] = useState(1);
  const [stoneid, setStoneid] = useState(1);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabledlist, setIsDisabledList] = useState(true);
  const [error, setError] = useState("");
  const [activestepval, setActiveSetpval] = useState(1);
  const [backval, setBackVal] = useState(1);
  const [mbDisabled, setmbDisabled] = useState(false);
  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const stoneDataclick = () => {
    // setActiveIndex(1);
    setStoneid(stonedata.find((stone) => stone.id === selectedItems.stone)?.id);
  };

  const handleItemClick = (type, id) => {
    setmbDisabled(true)
    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [type]: id,
    }));
    if (type == "stone") {
      selectedItems.size = "";
    }
    if (type == "stone") {
      setApistoneid(id);
    }
  };
  useEffect(() => {
    if (formData.MainCategories.category_name === "Engagement Ring") {
      setActiveSetpval(1);
    } else if (formData.MainCategories.category_name.trim() === "Dress Ring") {
      setActiveSetpval(2);
      setBackVal(12);
    } else if (formData.MainCategories.category_name.trim() === "Pendant") {
      setActiveSetpval(2);
      setBackVal(14);
    } else if (
      formData.MainCategories.category_name.trim() === "Wedding Band"
    ) {
      setActiveSetpval(3);
      setBackVal(11);
    } else if (formData.MainCategories.category_name.trim() === "Bracelet") {
      setActiveSetpval(2);
      setBackVal(16);
    } else if (formData.MainCategories.category_name.trim() === "Earrings") {
      setActiveSetpval(2);
      setBackVal(15);
    }
  }, []);
  useEffect(() => {
    setSelectedItems({
      stone: stonedata.find((stone) => stone.id === selectedItems.stone)?.name,
    });
  }, []);

  useEffect(() => {
    fetch(`${apiurl}/ring-builder/centerstone`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: bearertoken,
      },
    })
      .then((response) => response.json())
      .then((stonedata) => {
        setStoneData(stonedata.centerstone);
        handleItemClick('stone',formData.CenterStoneSingle.stoneid) 
        
      })

     // .then((stonedata) => setStoneData(stonedata.centerstone))
      .then((responsedata) => {
        //setStoneData(stonedata.centerstone);
        setStoneLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.log(error);
        setStoneLoading(false); // Set loading to false on error as well
      });
  }, []);

  useEffect(() => {
    if (selectedItems.stone) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    if (selectedItems.stone) {
      setIsDisabledList(false);
    } else {
      setIsDisabledList(false);
    }
  }, [selectedItems]);

  const handleSubmit = () => {
    document.getElementById("root").scrollIntoView();
    if (selectedItems.stone) {
      const stoneValue = stonedata.find(
        (stone) => stone.id === selectedItems.stone
      )?.name;
      const stoneId = stonedata.find(
        (stone) => stone.id === selectedItems.stone
      )?.id;

      updateFormData("CenterStoneSingle", {
        stonevalue: stoneValue,
        stoneid: stoneId,
      });
      updateFormData("MultiCenterStone1", "");
      updateFormData("MultiCenterStone2","");
      updateFormData("CenterStone","");
      
      //next();
      if (formData.MainCategories.category_name == "Engagement Ring") {
        next(3);
      } else if (
        formData.MainCategories.category_name.trim() == "Wedding Band"
      ) {
        next(5);
      } else if (formData.MainCategories.category_name.trim() == "Dress Ring") {
        next(5);
      } else if (formData.MainCategories.category_name.trim() == "Pendant") {
        next(5);
      } else if (formData.MainCategories.category_name.trim() == "Earrings") {
        next(5);
      } else if (formData.MainCategories.category_name.trim() == "Bracelet") {
        next(5);
      } else {
        next(3);
      }
    } else {
      //setError("Please select all options");
    }
  };

  return (
    <div>
      <div className="flex">
        <div>
          <h1 className="mb-textleft">
            {" "}
            {formData.MainCategories.category_name}{" "}
          </h1>
          <p className="lightfont ">
          <SubTitle></SubTitle>
             
          </p>
        </div>
        <div className="show-mobile">
          <img
            src={formData.MainCategories.category_img}
            width="120px"
            alt="Main Image"
          />{" "}
        </div>
      </div>

      <Stepper   onStepClick1={setpperback1}  onStepClick2={setpperback2}  onStepClick3={setpperback3}  onStepClick4={setpperback4}  activeStep={activestepval}></Stepper>

      <div className="show-mobile">
      { mbDisabled && <h4 className="mbselected">Selected</h4>} 
        <ul className="flex selectedvallist">
        {formData?.BraceletStyle?.bracelettype && (
                <li>{formData.BraceletStyle.bracelettype}</li>
              )}

              {formData?.EarringsCrown?.earringstype && (
                <li>{formData.EarringsCrown.earringstype}</li>
              )}

              {formData?.PendentStyle?.pendenttype && (
                <li>{formData.PendentStyle.pendenttype}</li>
              )}
            
              {formData?.WeddingStyle?.weddingtype && (
                <li>{formData.WeddingStyle.weddingtype}</li>
              )}
              
              {formData?.WeddingCrown?.weddingtype && (
                <li>{formData.WeddingCrown.weddingtype}</li>
              )} 

              {formData?.DressStyle?.styletype && (
                <li>{formData.DressStyle.styletype}</li>
              )} 
              
              {formData?.DesignCrown?.crowntype && (
                <li> {formData.DesignCrown.crowntype} </li>
              )}
              {formData?.DesignShank?.shanktype && (
                <li> {formData.DesignShank.shanktype} </li>
              )}
          {selectedItems.stone && (
            <li className="selected-value">
              {stonedata.find((stone) => stone.id === selectedItems.stone).name}
            </li>
          )}
        </ul>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full sm:w-2/5 md:w-40p lg:w-40p xl:w-40p  hide-mobile">
          <div className="mainImage   ">
            <div className="bg-white">
              <img
                src={formData.MainCategories.category_img}
                alt="Main Image"
              />
            </div>
          
            <ul className="flex selectedvallist">
            {formData?.BraceletStyle?.bracelettype && (
                <li>{formData.BraceletStyle.bracelettype}</li>
              )}

              {formData?.EarringsCrown?.earringstype && (
                <li>{formData.EarringsCrown.earringstype}</li>
              )}

              {formData?.PendentStyle?.pendenttype && (
                <li>{formData.PendentStyle.pendenttype}</li>
              )}
                
             {formData?.WeddingStyle?.weddingtype && (
                <li>{formData.WeddingStyle.weddingtype}</li>
              )}
              
              {formData?.WeddingCrown?.weddingtype && (
                <li>{formData.WeddingCrown.weddingtype}</li>
              )} 

              {formData?.DressStyle?.styletype && (
                <li>{formData.DressStyle.styletype}</li>
              )}
            
   
              {formData?.DesignCrown?.crowntype && (
                <li> {formData.DesignCrown.crowntype} </li>
              )}
              {formData?.DesignShank?.shanktype && (
                <li> {formData.DesignShank.shanktype} </li>
              )} 
              {selectedItems.stone && (
                <li className="selected-value">
                  {
                    stonedata.find((stone) => stone.id === selectedItems.stone)
                      .name
                  }
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="w-full sm:w-3/5 md:w-60p lg:w-60p xl:w-60p pl-15">
          {error && <div className="text-red text-center">{error}</div>}
          <div className="space-y-6">
            <AccordionItem
              title="Select center stone"
              content={
                stoneloading ? ( // Show loader if loading is true
                  <Loading />
                ) : (
                  <ul
                    onClick={() => stoneDataclick()}
                    className="grid grid-cols-2 sm:grid-cols-2 gap-4"
                  >
                    {stonedata.map((stone) => (
                      <li
                        className={`flex justify-between ${
                          selectedItems.stone === stone.id ? "active" : ""
                        }`}
                        onClick={() => handleItemClick("stone", stone.id)}
                        key={stone.id}
                      >
                        <div className="flex items-center gap-5">
                        <span style={{ fontSize: "30px" }}>
                            <img width={35} src={stone.url} />
                          </span>
                          {stone.name}
                        </div>
                        <input
                          type="radio"
                          checked={selectedItems.stone === stone.id}
                          onChange={() => {}}
                        />
                      </li>
                    ))}
                  </ul>
                )
              }
              isActive={activeIndex === 0}
              handleClick={() => handleClick(0)}
            />
          </div>

          <div className="flex  justify-between mt-4 mb-4 btnlist">
            <button className="backbtn" onClick={() => back(backval)}>
              {" "}
              <span> {"<"} </span>Go back
            </button>
            <button
              disabled={isDisabled}
              className={`nextbtn ${isDisabled ? "disable" : ""}`}
              onClick={handleSubmit}
            >
              Next <span>{">"}</span>{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CenterStoneSingle;
