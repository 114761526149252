import React from 'react'
import { useForms } from "../actions/FormContext";
function SubTitle() {
    const { formData } = useForms();
  return (
    <div>
        
        {  formData.MainCategories.category_name.trim() === "Engagement Ring" &&   "Let's design your engagement ring together"}
        {  formData.MainCategories.category_name.trim() === "Wedding Band" &&  "Let's  design your special wedding band together"}
        {  formData.MainCategories.category_name.trim() === "Dress Ring" && "Let's design your Dress ring together " }
        {  formData.MainCategories.category_name.trim() === "Pendant" &&  "Let's design your unique pendant together" }
        {  formData.MainCategories.category_name.trim() === "Earrings" &&   "Let's design your unique Earrings together" }
        {  formData.MainCategories.category_name.trim() === "Bracelet" &&    "Let's  design your unique Bracelet together " }
    </div>
  )
}

export default SubTitle