import "./App.css";
import React, { useState,useEffect } from "react";
import { FormProvider } from "./actions/FormContext";
import Home from "./components/Home";
 
 
function App() {
   
  useEffect(() => {  
    applyDynamicStyles();
  }, []);  // Empty dependency array ensures this effect only runs once on component moun
  const applyDynamicStyles = async () => {
  
    // Fetch API data
    //const response = await fetch('YOUR_API_URL');
   // const data = await response.json();

    // Assuming the data object contains fontFamily and color properties
   // const fontFamily = data.fontFamily;
   // const color = data.color;

    const fontFamily ="'Rubik', sans-serif";
    const color = "#003459";
    const subheading="#545454";

    // Create a new style element
    const style = document.createElement('style');

    // Inject the dynamic CSS rules
    style.textContent = `
      :root {
        --heading-fontfamily: ${fontFamily};
        --blue-font : ${color};
        --title-font-color:${color};
        --heading-font-color: ${color};
        --heading-title-color: ${subheading};
      }
   `;

   // Append the new style element to the head of the document
   document.head.append(style);
  };
  
 
  return (
    <div className="container  max-w-1000 containerpadding">
      {/* mx-auto px-4 */}

      <FormProvider>  
       <Home></Home>
      </FormProvider>
    </div>
  );
}

export default App;
