import React, { useState, useEffect } from "react";
import { IoIosArrowUp } from "react-icons/io";
import { useForms } from "../actions/FormContext";
import Stepper from "../shared/Stepper";
import Loading from "../shared/Loading";
import SubTitle from "./SubTitle";

const AccordionItem = ({ title, content, isActive, handleClick }) => (
  <div
    className={`accordianborder accordion-item ${
      !isActive ? "" : "open activeheader"
    }`}
  >
    <button
      className="flex accorHeader items-center justify-between w-full p-3 text-lg font-semibold  focus:outline-none"
      onClick={handleClick}
    >
      <span>{title}</span>
      <IoIosArrowUp
        className={`w-6 h-6 transition-transform duration-200 transform ${
          isActive ? "" : "rotate-180"
        }`}
      />
    </button>
    <div
      className={` bg-white contentlist  ${
        !isActive ? "slide-down " : "slide-up "
      }`}
    >
      <div>{content}</div>
    </div>
  </div>
);

const MulitiDesignYourRings = ({ next, back,setpperback1,setpperback2,setpperback3,setpperback4 }) => {
  const existingSelectedItems = JSON.parse(localStorage.getItem('selectedItems')) || {};
  const [activeIndex, setActiveIndex] = useState(0);
  const { updateFormData, formData, apiurl, bearertoken } = useForms();
  const [stonedata, setStoneData] = useState([]);
  const [stoneloading, setStoneLoading] = useState(true);
  const [apistoneid, setApistoneid] = useState(1);
  const [stoneid, setStoneid] = useState(1);
  const [isDisabled, setIsDisabled] = useState(true); 
  const [error, setError] = useState("");
  const [activestepval, setActiveSetpval] = useState(2);
  const [backval, setBackVal] = useState(1);
  const [selectedItems, setSelectedItems] = useState(existingSelectedItems);
  const [mbDisabled, setmbDisabled] = useState(true);
  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const stoneDataclick = () => {
    // setActiveIndex(1);
    setStoneid(stonedata.find((stone) => stone.id === selectedItems.stone)?.id);
  };
 // Anytime selectedItems changes, store it in localStorage
 useEffect(() => {
  localStorage.setItem('selectedItems', JSON.stringify(selectedItems));
}, [selectedItems]);

  const handleItemClick = (type, id) => { 
    // debugger;
    setmbDisabled(true)
    const indexes = selectedItems[type] ? [...selectedItems[type]] : [];
    const selectedIdIndex = indexes.indexOf(id);
    // alert("indexes0"+indexes[0])
    // alert("indexes1"+indexes[1])
    // alert("selectedIdIndex"+selectedIdIndex)
     var indexlenth = 2;
    if (selectedIdIndex === -1) {
      // Item not selected yet
      //alert("myindex"+ indexes.length)
    //  alert(id)
     // alert("secondid"+formData.MultiCenterStone2?.stone2?.stoneid + "index" +indexlenth )
      if(formData.MultiCenterStone2?.stone2?.stoneid == undefined){
        indexlenth = 3;
      }
      if (indexes.length < 2 || indexes[0]==indexes[1] ) {
        // Check max selection count
       // debugger;
      //  alert("selected")
    //  alert(indexes.length +"mylength")
        indexes.push(id);
      }
    } else {
      // Item is selected, unselect it
      indexes.splice(selectedIdIndex, 1);
    }

    setSelectedItems({
      ...selectedItems,
      [type]: indexes,
    });  
     
  }; 

  useEffect(() => {
    setSelectedItems({
      stone: stonedata.find((stone) => stone.id === selectedItems.stone)?.name,
    });
  }, []);
 
useEffect(() => {
  const data = {
        myUniqueVar: 'Multisones', 
      };
  fetch(`${apiurl}/ring-builder/centerstone`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: bearertoken,
    },
  })
    .then((response) => response.json())
    //.then((stonedata) => setStoneData(stonedata.centerstone))
    .then((stonedata) => {
      setStoneData(stonedata.centerstone);
       
      if (formData.MultiCenterStone1?.stone1?.stoneid && formData.MultiCenterStone2?.stone2?.stoneid) {
        // two values exist   
       // alert("2itemsselct")     
       handleItemClick(existingSelectedItems);
    } else if (formData.MultiCenterStone1?.stone1?.stoneid) {
        // only one value exists  
      //  alert("1itemse")        
        handleItemClick(existingSelectedItems);
    }

     //handleItemClick('stone',[formData.MultiCenterStone1.stone1.stoneid,formData.MultiCenterStone2.stone2.stoneid]) 
    })
    .then((responsedata) => {       
      setStoneLoading(false); // Set loading to false when data is fetched
    })
    .catch((error) => {
      console.log(error);
      setStoneLoading(false); // Set loading to false on error as well
    });
}, []);

useEffect(() => {
  if (formData.MainCategories.category_name === "Wedding Band") {
    setActiveSetpval(3);    
    setBackVal(11);
  } else if (formData.MainCategories.category_name.trim() === "Dress Ring") {
    setActiveSetpval(2);
    setBackVal(12);
  }  else if (formData.MainCategories.category_name.trim() === "Pendant") {
    setActiveSetpval(2);
     setBackVal(14);
  } else if (formData.MainCategories.category_name.trim() === "Bracelet") {
    setActiveSetpval(2);
     setBackVal(16);
  }   
}, []); 

  useEffect(() => { 
    if (selectedItems?.stone?.length == 0 || selectedItems?.stone?.length == undefined) {    
     setIsDisabled(true);
    } else {  
      setIsDisabled(false);
    }

    
   
  }, [selectedItems]);

  const handleSubmit = () => { 
    document.getElementById("root").scrollIntoView();
    if (selectedItems.stone) { 
      const [stone1, stone2]  = selectedItems.stone.map((id) => {
        const stoneValue = stonedata.find((stone) => stone.id === id)?.name;
        const stoneId = stonedata.find((stone) => stone.id === id)?.id;
        return { stonevalue: stoneValue, stoneid: stoneId };
    }); 
    
    const combinedStone = { 
      stone1: stone1, 
      stone2: stone2
    };
    updateFormData("MultiCenterStone", combinedStone);    
    updateFormData("MultiCenterStone1", {stone1:stone1});
    updateFormData("MultiCenterStone2", {stone2:stone2});
    updateFormData("CenterStone","");
    updateFormData("CenterStoneSingle","");
    
      next(5);
    } else {
      //setError("Please select all options");
    }
  };

  return (
    <div>
      <div className="flex">
        <div>
          <h1 className="mb-textleft">
            {" "}
            {formData.MainCategories.category_name}{" "}
          </h1>
          <p className="lightfont ">
            <SubTitle></SubTitle>
          </p>
        </div>
        <div className="show-mobile">
          <img
            src={formData.MainCategories.category_img}
            width="120px"
            alt="Main Image"
          />{" "}
        </div>
      </div>

      <Stepper onStepClick1={setpperback1}  onStepClick2={setpperback2}  onStepClick3={setpperback3}  onStepClick4={setpperback4} activeStep={activestepval}></Stepper>

      <div className="show-mobile">
      { mbDisabled && <h4 className="mbselected">Selected</h4>}
        
        <ul className="flex selectedvallist">
           {formData?.BraceletStyle?.bracelettype && (
              <li>{formData.BraceletStyle.bracelettype}</li>
            )} 
            {formData?.DressStyle?.styletype && (
            <li>{formData.DressStyle.styletype}</li>
             )}
              {formData?.WeddingStyle?.weddingtype && (
            <li>{formData.WeddingStyle.weddingtype}</li>
             )}
               {formData?.WeddingCrown?.weddingtype && (
            <li>{formData.WeddingCrown.weddingtype}</li>
             )}
          
              {stonedata
                .filter((stone) => selectedItems?.stone?.includes(stone.id))
                .map((stone) => (
                  <li className="selected-value" key={stone.id}>
                    {stone.name}
                  </li>
                ))}
           
        </ul>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full sm:w-2/5 md:w-40p lg:w-40p xl:w-40p  hide-mobile">
          <div className="mainImage   ">
            <div className="bg-white">
              <img
                src={formData.MainCategories.category_img}
                alt="Main Image"
              />
            </div>

            <ul className="flex selectedvallist">
              {formData?.BraceletStyle?.bracelettype && (
              <li>{formData.BraceletStyle.bracelettype}</li>
            )} 
            {formData?.DressStyle?.styletype && (
            <li>{formData.DressStyle.styletype}</li>
             )}
              {formData?.WeddingStyle?.weddingtype && (
            <li>{formData.WeddingStyle.weddingtype}</li>
             )}
               {formData?.WeddingCrown?.weddingtype && (
            <li>{formData.WeddingCrown.weddingtype}</li>
             )}
              {stonedata
                .filter((stone) => selectedItems?.stone?.includes(stone.id))
                .map((stone) => (
                  <li className="selected-value" key={stone.id}>
                    {stone.name}
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="w-full sm:w-3/5 md:w-60p lg:w-60p xl:w-60p pl-15">
          {error && <div className="text-red text-center">{error}</div>}
          <div className="space-y-6">
            <AccordionItem
              
              title={
                <div className="text-left">
                  <span>Select your stones</span>
                  <p className="lightfont">
                  Please choose your favorite stones (Note: You can select max 2 stones)
                  </p>
                </div>
              }
              content={
                stoneloading ? ( // Show loader if loading is true
                  <Loading />
                ) : (
                  <div>
                    <ul
                      onClick={() => stoneDataclick()}
                      className="grid grid-cols-2 sm:grid-cols-2 gap-4"  >
                      {stonedata.map((stone, index) => (
                        <li
                          className={`flex justify-between ${
                            selectedItems.stone &&
                            selectedItems.stone.includes(stone.id)
                              ? "active"
                              : ""
                          }`}
                          onClick={() => handleItemClick("stone", stone.id)}
                          key={stone.id}
                        >
                          <div className="flex items-center gap-5">
                            <span style={{ fontSize: "30px" }}>
                              <img width={35} src={stone.url} />
                            </span>
                            {stone.name}
                          </div>
                          <input
                            type="checkbox"
                            checked={
                              selectedItems.stone &&
                              selectedItems.stone.includes(stone.id)
                            }
                            onChange={() => {}}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              }
              isActive={activeIndex === 0}
              handleClick={() => handleClick(0)}
            />
          </div>

          <div className="flex  justify-between mt-4 mb-4 btnlist">
            <button className="backbtn" onClick={() => back(backval)}>
              {" "}
              <span> {"<"} </span>Go back
            </button>
            <button
              disabled={isDisabled}
              className={`nextbtn ${isDisabled ? "disable" : ""}`}
              onClick={handleSubmit}
            >
              Next <span>{">"}</span>{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MulitiDesignYourRings;
